/* eslint-disable no-useless-catch */
const url = 'https://hasura.boorran.com/v1/metadata'
const headers = {
  'X-Hasura-Role': 'admin',
  'x-hasura-admin-secret': 'Boorran168',
}
const createHasuraCronJob = async (job) => {
  try {
    const req = await fetch(url, {
      headers,
      method: 'POST',
      body: JSON.stringify({
        type: 'create_cron_trigger',
        args: {
          name: job.name,
          webhook: job.webhook,
          schedule: job.cron_schedule,
          payload: job.payload,
          include_in_metadata: false,
          comment: 'sample_cron commment',
        },
      }),
    })

    return req
  } catch (err) {
    alert(err)
    throw err
  }
}

const removeHasuraCronJob = async (job) => {
  try {
    const req = await fetch(url, {
      headers,
      method: 'POST',
      body: JSON.stringify({
        type: 'delete_cron_trigger',
        args: {
          name: job.name,
        },
      }),
    })

    return req
  } catch (err) {
    alert(err)
    throw err
  }
}

export { createHasuraCronJob, removeHasuraCronJob }
