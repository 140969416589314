/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-async-promise-executor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonBase, Grid, Typography } from '@mui/material'
import get from 'lodash/get'
import moment from 'moment'
import { Modal } from '../../components/boorran'
import { GoogleMap } from '../../components'
import { setTodayAttendance } from '../../redux/actions'
import utils from '../../utils'

const ModalAttendance = ({ visible, staffId, clockInTime, handleCancel }) => {
  const dispatch = useDispatch()
  const auth = useSelector((store) => store.auth)
  const { defaultLocation } = useSelector((store) => store.settings)
  const { data: attendance } = useSelector((store) => store.attendances)
  const { ipAddress, attendance: attendanceConfig } = useSelector((store) => store.settings)
  const [time, setTime] = useState(null)

  const {
    allowIpAddresses,
    delayClockInTime,
    cutdownClockInLate,
    clockInTime: clockInConfig,
    locations,
  } = attendanceConfig

  const userLocation = auth.data.metadata?.geolocation?.location
  const userRadius = auth.data.metadata?.geolocation?.radius
  const circleRadius =
    userRadius || locations[auth.data.role]?.radius || utils.constants.defaultCircleRadius
  const roleLocation = userLocation || locations[auth.data.role]?.location || defaultLocation

  const currentTime = moment().toISOString()
  const digitalClock = moment(time).format('hh:mm:ss A')
  const delayClockIn = Number(delayClockInTime)
  const cutdownClockIn = Number(cutdownClockInLate)
  const isOnTimeClockIn = moment(currentTime)
    .add(delayClockIn, 'minutes')
    .isSameOrBefore(clockInConfig)

  const status = isOnTimeClockIn ? 'on-time' : 'late'
  const type = !attendance['clock-in'] ? 'clock-in' : 'clock-out'
  const authWorkHours = get(auth, ['data', 'metadata', 'workingHours'], 8)
  const totalWorkHours = isOnTimeClockIn
    ? authWorkHours
    : moment(authWorkHours).subtract(cutdownClockIn, 'minutes')

  const attendanceParams = window.btoa(
    `staffId=${staffId}&time=${clockInTime}&type=${type}&totalWorkHours=${totalWorkHours}&status=${status}`
  )
  const url = `${window.location.origin}/attendance?boorran=${attendanceParams}`

  useEffect(() => {
    realtimeClock()
  }, [])

  const realtimeClock = () => {
    setTime(Number(moment().format('x')))
    setTimeout(realtimeClock, 1000)
  }

  const validateData = () => {
    const currentDistance = google.maps.geometry.spherical.computeDistanceBetween(
      window.currentPosition,
      roleLocation
    )
    return new Promise(async (resolve, reject) => {
      // if (isEmpty(allowIpAddresses || [])) resolve(true)
      //
      // const ipAddresses = allowIpAddresses.map((ip) => ip.value)
      // const isValid = ipAddresses.includes(ipAddress)
      const isValid = currentDistance <= circleRadius
      if (isValid || !window?.currentPosition) {
        resolve(true)
      } else {
        reject(`Offsite Clock-In / Disable Location`)
      }
    })
  }

  const timeShiftLabel = () => {
    let label = ''
    const isMorning = moment().format('HH') >= 0 && moment().format('HH') <= 11
    const isAfterNoon = moment().format('HH') >= 11 && moment().format('HH') <= 18

    if (isMorning) {
      label = 'Good Morning 🌤'
    } else if (isAfterNoon) {
      label = 'Good Afternoon ☀️'
    } else {
      label = 'Good Evening 🌙'
    }

    return label
  }

  const onClockInClick = async () => {
    try {
      await validateData()

      const createdAt = moment(Number(clockInTime)).toISOString(true)
      const expiredAt =
        status === 'late'
          ? moment(createdAt)
              .add(authWorkHours, 'h')
              .subtract(cutdownClockIn, 'minutes')
              .toISOString(true)
          : moment(createdAt).add(authWorkHours, 'h').toISOString(true)
      const { lat, lng } = window?.currentPosition || {}

      const payload = {
        type,
        status,
        staffId,
        expiredAt,
        createdAt,
        ipAddress,
        location: `${lat} , ${lng}`,
      }
      dispatch(setTodayAttendance(payload))
      handleCancel()
    } catch (err) {
      alert(err)
    }
  }

  const onModalClose = () => {
    return null
  }

  const infoWindow = new google.maps.InfoWindow()

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const pos = { lat: position.coords.latitude, lng: position.coords.longitude }
        window.currentPosition = pos
      },
      () => {
        handleLocationError(true, infoWindow, pos)
      }
    )
  } else {
    alert("Your browser doesn't support Geolocation.")
  }

  return (
    <Modal visible={visible} onClose={onModalClose}>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
          {timeShiftLabel()}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
          {digitalClock}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            px: 2,
            py: 0.5,
            color: 'white',
            borderRadius: 2,
            fontWeight: 'bold',
            bgcolor: isOnTimeClockIn ? 'green' : 'red',
          }}
        >
          {isOnTimeClockIn ? 'On-Time Clock In' : 'Clock In Late'}
        </Typography>

        {/* <QRCodeSVG */}
        {/*  size={220} */}
        {/*  value={url} */}
        {/*  imageSettings={{ src: boorranLogo, height: 55, width: 55 }} */}
        {/* /> */}
        <div style={{ width: '100%' }}>
          <GoogleMap
            myLocation={window.currentPosition}
            location={roleLocation}
            circleRadius={utils.constants.defaultCircleRadius}
          />
        </div>

        <Typography textAlign="center" variant="subtitle2" sx={{ fontSize: 15, my: 2 }}>
          {/* Please scan the QR Code to tracking your attendance or Click */}
          Please stay within the radius to clock in
          <ButtonBase
            onClick={onClockInClick}
            disabled={type !== 'clock-in'}
            sx={{
              px: 1.5,
              py: 0.5,
              mx: 0.5,
              fontSize: 13,
              borderRadius: 2,
              bgcolor: 'background.default',
              color: 'text.primary',
              fontWeight: 'bold',
            }}
          >
            {type === 'clock-in' ? 'Clock In' : 'Clock Out'}
          </ButtonBase>{' '}
        </Typography>
      </Grid>
    </Modal>
  )
}

export default ModalAttendance

function handleLocationError(browserHasGeolocation, infoWindow, pos) {
  infoWindow.setPosition(pos)
  infoWindow.setContent(
    browserHasGeolocation
      ? 'Error: The Geolocation service failed.'
      : "Error: Your browser doesn't support geolocation."
  )
  infoWindow.open(map)
}
