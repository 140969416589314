import React from 'react'
import { Typography } from '@mui/material'
import Input from './Input'

const NewForm = (props) => {
  const { fields, action = console.log, title } = props

  return (
    <div>
      <Typography variant="subtitle2" fontSize={17} sx={{ mt: 2, textAlign: 'center' }}>
        {title}
      </Typography>
      {fields.map((field, i) => (
        <Field key={i} {...field} action={action} />
      ))}
    </div>
  )
}

const Field = (props) => {
  const { fields, action } = props

  return (
    <>
      {fields && fields.length && (
        <div>
          {fields.map((field, index) => (
            <Input key={index} {...field} action={action} />
          ))}
        </div>
      )}
      {!fields && (
        <div>
          <Input {...props} />
        </div>
      )}
    </>
  )
}

export default NewForm
