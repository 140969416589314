/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react'
// import styled from 'styled-components';
// import { fustyle } from 'next-fucss/utils';
import { useNavigate } from 'react-router-dom'
import { Loader, Pagination, Table } from 'semantic-ui-react'
import { Button, ButtonGroup, Grid } from '@mui/material'

import { Card, Input } from '.'
import Filter from './Filter'

// const Footer = styled.div`${props => fustyle(`dp:flx ai:c jc:sb${props.noFooterLeft ? ' jc:fe' : ''}`)}`;

export default (props) => {
  const {
    rowActions = [],
    noTitle,
    getSelected,
    center,
    footerLeft,
    subtitle,
    actions = [],
    allowSelect,
    data = [],
    heads = [],
    pagination,
    noPagination,
    title,
    loading,
    handlePageChange,
    filters = {},
    filterFields = [],
    handleFilters = console.log,
    inlineFilter,
    extrafiltersComp,
    handleSearchChange,
  } = props
  const [activePage, setActivePage] = useState(pagination?.page || 1)
  const navigate = useNavigate()

  const [selected, setSelected] = useState([])

  const handlePaginationChange = (e, { activePage: page }) => {
    setActivePage(page)

    if (handlePageChange) handlePageChange(page)
  }

  useEffect(() => setActivePage(pagination?.page), [pagination?.page])
  useEffect(() => getSelected && getSelected(selected), [selected])

  return (
    <>
      {noTitle ? null : !!title && typeof title === 'string' ? (
        <Card>
          <h1>{title}</h1>
          {!!subtitle && <h4>{subtitle}</h4>}
        </Card>
      ) : (
        title()
      )}
      {!!filterFields.length && (
        <Card>
          <Filter
            filters={filters}
            handleFilters={handleFilters}
            filterFields={filterFields}
            inlineFilter={inlineFilter}
            handleSearchChange={handleSearchChange}
          />
          {extrafiltersComp}
        </Card>
      )}
      <Card>
        {allowSelect && !!actions && !!actions.length && !!selected.length && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <Button
              // style={{ margin: '4px' }}
              variant="contained"
              onClick={() => setSelected([])}
              sx={{ m: 0.5 }}
            >
              Cancel
            </Button>
            <ButtonGroup />
            {actions.map(({ content, icon, color, action, disabled }, index) => (
              <Fragment key={content}>
                <Button
                  variant="contained"
                  sx={{ m: 0.5 }}
                  disabled={disabled}
                  startIcon={icon}
                  color={color}
                  onClick={() => {
                    action && action(selected)
                    setTimeout(() => setSelected([]), 500)
                  }}
                >
                  {content}
                </Button>
                {index < actions.length - 1 && <ButtonGroup />}
              </Fragment>
            ))}
          </div>
        )}
        <Table
          stackable
          structured
          attached
          compact
          basic="very"
          selectable={(data && data.length && true) || false}
        >
          <Table.Header>
            <Table.Row>
              {allowSelect && (
                <Table.HeaderCell>
                  <Input
                    type="checkbox"
                    action={() => {
                      const allIds = data.map((item) => item.id) || []
                      setSelected(arraysEqual(allIds, selected) ? [] : allIds)
                    }}
                    noLabel
                    value={
                      data.length &&
                      arraysEqual(
                        data.map((item) => item.id),
                        selected
                      )
                    }
                  />
                </Table.HeaderCell>
              )}
              {heads.map((headCell, index) => (
                <Table.HeaderCell singleLine textAlign={(center && 'center') || 'left'} key={index}>
                  {headCell}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!data.length ? (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan={heads.length}>
                  {(loading && (
                    <Loader className="center" active inline="centered">
                      Fetching your data
                    </Loader>
                  )) || <>Empty List</>}
                </Table.Cell>
              </Table.Row>
            ) : (
              data.map((row, index) =>
                row._href ? (
                  <Table.Row key={`${index}_tlink`} onClick={(e) => navigate(row._href)}>
                    {!!allowSelect && (
                      <Table.Cell collapsing>
                        <Input
                          className="center"
                          type="checkbox"
                          noLabel
                          value={selected && selected.includes(row.id)}
                          action={(vals, inputProps, event) => {
                            const newSelected = selected.includes(row.id)
                              ? selected.filter((id) => id !== row.id)
                              : [...selected, row.id]
                            event && event.stopPropagation()
                            setSelected(newSelected)
                          }}
                        />
                      </Table.Cell>
                    )}
                    <Item item={row} i={index} heads={props.heads} />
                    <AdditonalActions row={row} items={rowActions} />
                  </Table.Row>
                ) : (
                  <Table.Row key={`${index}_tdata`}>
                    {!!allowSelect && (
                      <Table.Cell collapsing>
                        <Input
                          className="center"
                          type="checkbox"
                          noLabel
                          value={selected && selected.includes(row.id)}
                          action={console.log}
                        />
                      </Table.Cell>
                    )}
                    <Item item={row} i={index} heads={props.heads} />
                    <AdditonalActions row={row} items={rowActions} />
                  </Table.Row>
                )
              )
            )}
          </Table.Body>
        </Table>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>{!!footerLeft && footerLeft()}</Grid>
          <Grid item>
            {!noPagination && (
              <Pagination
                size="mini"
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                pointing={null}
                boundaryRange={null}
                siblingRange={null}
                activePage={activePage}
                onPageChange={handlePaginationChange}
                totalPages={pagination.pages}
              />
            )}
          </Grid>
        </Grid>

        {/* <Footer noFooterLeft={!footerLeft}>
          { !!footerLeft && footerLeft() }
          {
            !noPagination && <Pagination
              activePage={activePage}
              onPageChange={handlePaginationChange}
              totalPages={pagination.pages}
            />
          }
        </Footer> */}
      </Card>
    </>
  )
}

const Item = ({ item, heads, i }) =>
  heads.map((key, index) => {
    const value = item[key]

    return (
      <Table.Cell
        singleLine={!value?.multipleLine}
        textAlign={(value?.center && 'center') || 'left'}
        key={index}
      >
        {value && getValue(value)}
        {value && value.title}
        {value && value.component && <value.component {...value.props} />}
      </Table.Cell>
    )
  })

const AdditonalActions = ({ row, items }) => {
  if (!items?.length) return <></>

  return (
    <>
      {items.map((item, index) => {
        return (
          <Table.Cell textAlign="center" key={index}>
            <Button
              variant="contained"
              sx={{ m: 0.5 }}
              disabled={item.disabled(row)}
              startIcon={item?.icon}
              color={item?.color}
              onClick={(e) => {
                e.stopPropagation()
                item.action(row)
              }}
            >
              {item.text}
            </Button>
          </Table.Cell>
        )
      })}
    </>
  )
}

const getValue = (value) => (value && typeof value === 'object' ? value.value : value)

function arraysEqual(_arr1, _arr2) {
  if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) return false

  const arr1 = Object.assign([], _arr1).concat().sort()
  const arr2 = Object.assign([], _arr2).concat().sort()

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }

  return true
}
