import { wsGQLSubscribe, wsGQLUnsubscribe } from 'fetchier'

import { HASURA_WSS } from '../config'
import gq from '../queries'
import { CHART_OF_ACCOUNT_TYPES } from '../reducers/chartOfAccounts'
import { fields, qSubscribeChartOfAccounts } from '../../queries/chartOfAccounts'

const setLoading = (bool) => ({
  type: CHART_OF_ACCOUNT_TYPES.SET_LOADING,
  payload: bool,
})

const setData = (data) => ({
  type: CHART_OF_ACCOUNT_TYPES.CHART_OF_ACCOUNTS_FETCHED,
  payload: data,
})

const setEnum = (data) => ({
  type: CHART_OF_ACCOUNT_TYPES.CHART_OF_ACCOUNTS_ENUM_FETCHED,
  payload: data,
})

export const getChartOfAccounts =
  (tab, unsyncAfterComplete = false) =>
  async (dispatch) => {
    dispatch(setLoading(true))

    const action = (data) => {
      const chartOfAccounts = {}

      data.forEach((chartOfAccount) => {
        const { account_type: accountType } = chartOfAccount

        chartOfAccounts[accountType] = chartOfAccounts[accountType] || []
        chartOfAccounts[accountType].push(chartOfAccount)
      })

      dispatch(setData(chartOfAccounts))

      if (unsyncAfterComplete) {
        dispatch(setEnum(data))
        wsGQLUnsubscribe({ url: HASURA_WSS, id: 'chart-of-accounts' })
      }
    }

    wsGQLUnsubscribe({ url: HASURA_WSS, id: 'chart-of-accounts' })

    const subscription = {
      id: 'chart-of-accounts',
      query: qSubscribeChartOfAccounts(tab),
      action,
    }

    return wsGQLSubscribe({ url: HASURA_WSS, subscription })
  }

export const subscribeSingleChartOfAccount = (id) => async (dispatch) => {
  if (!id) {
    wsGQLUnsubscribe({ url: HASURA_WSS, id: 'chart-of-account' })
    dispatch({
      type: CHART_OF_ACCOUNT_TYPES.CHART_OF_ACCOUNT_FETCHED,
      payload: {},
    })

    return
  }

  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'chart-of-account' })

  dispatch(setLoading(true))

  const action = (data) => {
    dispatch({
      type: CHART_OF_ACCOUNT_TYPES.CHART_OF_ACCOUNT_FETCHED,
      payload: data[0],
    })
  }

  const subscription = {
    id: 'chart-of-account',
    query: gq.itemIdById(id, 'boorran_ChartOfAccounts', fields),
    action,
  }

  wsGQLSubscribe({ url: HASURA_WSS, subscription })
}

export const updateChartOfAccount = (obj) => async (dispatch) => {
  try {
    dispatch(setLoading(true))

    const data = Object.assign(Array.isArray(obj) ? [] : {}, obj)
    const columns = Object.keys(data[0] || data).join(' ')

    await gq.gqRequest(gq.qUpsert('boorran_ChartOfAccounts', columns, 'ChartOfAccounts_pkey'), {
      obj: data,
    })
    dispatch(setLoading(false))
  } catch (error) {
    dispatch(setLoading(false))

    throw error
  }
}
