import React from 'react'
import { Dropdown, Input } from 'semantic-ui-react'
import {
  Box,
  Grid,
  Paper,
  Button,
  Checkbox,
  Typography,
  CircularProgress,
  FormControlLabel,
} from '@mui/material'
import { some } from 'lodash'

const PaymentSummary = ({
  address,
  loading,
  customer,
  discount,
  orderItems,
  discountType,
  shippingPrice,
  paymentMethod,
  paymentMethods = [],
  setDiscount,
  setDiscountType,
  setPaymentMethod,
  setShippingPrice,
  calculateDiscount,
  calculateSubTotal,
  calculateTotal,
  handleCreate,
  freeDelivery,
  handleFreeDelivery,
  goBack,
}) => {
  let discountAmount = parseFloat(discount).toFixed(2)
  const subTotalAmount = calculateSubTotal()
  const isOrderItemDiscount = some(orderItems, (item) => item.discount > 0)

  if (!isOrderItemDiscount) {
    discountAmount = calculateDiscount()
  }

  return (
    <Paper sx={{ p: 2, mt: 2 }}>
      <Typography mt={1} variant="h6">
        Payment
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={freeDelivery} onClick={handleFreeDelivery} />
              }
              label="Free Delivery"
            />
          </Box>

          <Box sx={{ mt: 1 }}>
            <Typography>Discount</Typography>
            <Input
              fluid
              type="number"
              value={discount}
              placeholder="Amount"
              labelPosition="right"
              disabled={isOrderItemDiscount}
              onChange={(e) => setDiscount(e.target.value)}
              label={
                <Dropdown
                  defaultValue="$"
                  disabled={isOrderItemDiscount}
                  options={[
                    { key: '$', text: '$', value: '$' },
                    { key: '%', text: '%', value: '%' },
                  ]}
                  value={discountType}
                  onChange={(e, target) => setDiscountType(target.value)}
                />
              }
            />
          </Box>

          {!freeDelivery ? (
            <Box sx={{ mt: 1 }}>
              <Typography>Delivery Fee</Typography>
              <Input
                fluid
                type="number"
                placeholder="Delivery Fee"
                value={shippingPrice}
                onChange={(e) => setShippingPrice(e.target.value)}
              />
            </Box>
          ) : null}

          <Box sx={{ mt: 1 }}>
            <Typography>Payment Method</Typography>
            <Dropdown
              fluid
              selection
              placeholder="Select Payment Method"
              value={paymentMethod}
              options={paymentMethods.filter((item) =>
                ['Pick Up', ' Cash on Delivery (COD)', 'Province'].includes(item.value)
              )}
              onChange={(e, target) => setPaymentMethod(target.value)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ textAlign: 'right', mt: 1 }}>
            <Typography variant="h6">SubTotal: ${subTotalAmount}</Typography>
            <Typography variant="h6">Discount: - ${discountAmount}</Typography>
            <Typography variant="h6">
              Shipping: ${parseFloat(shippingPrice || 0).toFixed(2)}
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Total: ${calculateTotal()}
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'right', mt: 4 }}>
            <Button
              sx={{ mr: 1, bgcolor: 'background.default', color: 'text.primary' }}
              variant="outlined"
              onClick={goBack}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              disabled={
                !orderItems.length ||
                !customer ||
                !paymentMethod ||
                !address ||
                (address && address === 'N/A') ||
                loading
              }
              onClick={handleCreate}
            >
              Create{' '}
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PaymentSummary
