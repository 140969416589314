import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Grid, Typography } from '@mui/material'
import { Check, Cancel } from '@mui/icons-material'

import utils from '../../../utils'

import Input from '../../Input'
import Modal from '../../boorran/Modal'
import ImagePicker from '../../ImagePicker'

import Telegram from '../../../services/telegram'

const ModalFeedback = ({ open, onClose }) => {
  const {
    auth: { data: user },
  } = useSelector((store) => store)

  const [image, setImage] = useState(null)
  const [subject, setSubject] = useState('')
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)
  const [description, setDescription] = useState('')

  const onSubmit = async () => {
    try {
      setLoading(true)
      let url = imageUrl

      if (image) {
        url = await utils.helpers.uploadToFirebase({
          file: image,
          path: `feedback/${user.id}-${moment().format('x')}`,
        })
      }

      const req = await Telegram.sendMessage({
        disable_notification: false,
        parse_mode: 'markdown',
        chat_id: '-835595010',
        text: url
          ? `*Subject*: ${subject}\n*Description*:${description}\n[Attachment](${url})`
          : `*Subject*: ${subject}\n*Description*:${description}`,
      })

      if (req.status !== 200) throw req

      onClose()
    } catch (err) {
      setLoading(false)
      alert(err.message)
      console.error(err)
    }

    return null
  }

  const ACTIONS = [
    {
      icon: <Cancel />,
      content: 'Cancel',
      color: 'error',
      action: onClose,
    },
    {
      icon: <Check />,
      content: 'Submit',
      color: 'primary',
      loading,
      disabled: !subject || !description,
      action: onSubmit,
    },
  ]

  return (
    <Modal visible={open} onClose={onClose} actions={ACTIONS}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Feedback
      </Typography>

      <Grid item sx={{ mb: 2 }}>
        <Typography sx={{ fontSize: 14 }}>Subject</Typography>
        <Input
          value={subject}
          placeholder="eg: Bug, Improvement, ...."
          action={({ name, value }) => setSubject(value)}
        />
      </Grid>

      <Grid item sx={{ mb: 2 }}>
        <Typography sx={{ fontSize: 14 }}>Description</Typography>
        <Input
          rows={5}
          type="textarea"
          value={description}
          placeholder="Describe on what you want"
          action={({ name, value }) => setDescription(value)}
        />
      </Grid>

      <Typography sx={{ textAlign: 'center', fontWeight: '600', fontSize: 15, mb: 2 }}>
        Attachment
      </Typography>
      <Grid item sx={{ mb: 2 }}>
        <Typography sx={{ fontSize: 14 }}>Paste Image / Image URL</Typography>
        <Input
          value={imageUrl}
          placeholder="eg: https://photo.com or ctr + v"
          action={({ name, value }) => setImageUrl(value)}
        />
      </Grid>

      <div style={{ textAlign: 'center', marginTop: 15, color: 'gray' }}>
        ----------------- OR -----------------
      </div>
      <ImagePicker
        value={image}
        style={{ margin: '20px 0px 20px 0px' }}
        onChange={(file) => setImage(file)}
      />
    </Modal>
  )
}

export default ModalFeedback
