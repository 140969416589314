/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from 'react'
import { Button, Grid, Popup } from 'semantic-ui-react'
import { useSelector } from 'react-redux'

import { Form, Input } from './index'

export default (props) => {
  const {
    filterFields,
    handleFilters,
    handleSearchChange,
    filters,
    inlineFilter = false,
    children,
  } = props
  const [showDropdown, setDropdown] = useState()
  const {
    auth: { data = {} },
  } = useSelector((store) => store)

  return (
    <>
      {inlineFilter && (
        <>
          <Form fields={filterFields} action={handleFilters} />
          {children}
        </>
      )}
      {!inlineFilter && data.role === 'admin' && (
        <Grid columns="equal" stackable>
          <Grid.Column width={13}>
            <Input
              noLabel
              live={400}
              name="keywords"
              action={handleFilters}
              onKeyUp={handleSearchChange}
              value={filters.keywords}
              placeholder="Search for orders..."
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Popup
              onClose={() => setDropdown(false)}
              className="wide"
              position="bottom right"
              on="click"
              trigger={
                <Button
                  fluid
                  labelPosition="right"
                  content={showDropdown ? 'Close Filters' : 'Show Filters'}
                  icon={showDropdown ? 'sort up' : 'sort down'}
                  toggle
                  active={showDropdown}
                  onClick={() => setDropdown(!showDropdown)}
                />
              }
            >
              <div className="filter">
                <Form fields={filterFields} action={handleFilters} />
                <Button
                  fluid
                  name="clear"
                  labelPosition="right"
                  icon="delete"
                  negative
                  content="Clear filters"
                  disabled={Object.keys(filters).length === 0}
                  onClick={(_e, { name }) => handleFilters({ name })}
                />
              </div>
            </Popup>
          </Grid.Column>
        </Grid>
      )}
      {!inlineFilter && ['manager', 'finance'].includes(data.role) && (
        <Grid columns="equal" stackable>
          <Grid.Column width={16}>
            {/* <TextField
              variant="outlined"
              placeholder="Search for orders...1"
              fullWidth
              margin="normal"
              Value={filters.keywords}
              onChange={handleFilters}
            /> */}
            <Input
              noLabel
              live={400}
              name="keywords"
              action={handleFilters}
              onKeyUp={handleSearchChange}
              value={filters.keywords}
              placeholder="Search for orders..."
            />
          </Grid.Column>
        </Grid>
      )}
    </>
  )
}
