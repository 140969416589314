import reduce from 'lodash/reduce'
import find from 'lodash/find'
import { DISCOUNT_TYPES as PRODUCT_TYPES } from '../reducers/discounts'
import gq from '../queries'
import {
  qDiscounts,
  insertDiscount,
  deleteDiscount,
  updateDiscount,
  updateDiscounts,
} from '../../queries/discounts'
import { fetchProductsTitle } from './products'

export const fetchDiscounts = () => async (dispatch) => {
  try {
    const { boorran_Discounts: discounts } = await gq.gqRequest(qDiscounts)
    const productIds = discounts.map((dis) => dis.productId.toString())
    const { boorran_Products: PRODUCTS_TITLE } = await fetchProductsTitle(productIds)

    const DISCOUNTS = reduce(
      discounts,
      (result, disc) => {
        const productName = PRODUCTS_TITLE.find((pro) => pro.id === disc.productId).title

        result.push({
          ...disc,
          productName,
        })

        return result
      },
      []
    )

    dispatch({
      type: PRODUCT_TYPES.FETCH_DISCOUNTS,
      payload: DISCOUNTS,
    })
  } catch (error) {
    console.log(`Error fetch discounts: ${error.message}`)
  }
}

export const createDiscount = (data) => async (dispatch) => {
  try {
    const variables = { request: data }
    const response = await gq.gqRequest(insertDiscount, variables)

    const responseDiscount = response?.insert_boorran_Discounts?.returning[0] || false

    if (!responseDiscount) throw Error

    dispatch({
      type: PRODUCT_TYPES.CREATE_DISCOUNT,
      payload: { ...responseDiscount, productName: responseDiscount.productName.title },
    })
  } catch (err) {
    alert(`Fail to create discount: ${err.message}`)
  }
}

export const removeDiscount = (discountIds) => async (dispatch, getState) => {
  try {
    const { data: discounts } = getState().discounts

    const variables = { discountIds }
    await gq.gqRequest(deleteDiscount, variables)

    const newDiscounts = discounts.filter((dis) => !discountIds.includes(dis.id))

    dispatch({
      type: PRODUCT_TYPES.DELETE_DISCOUNT,
      payload: newDiscounts,
    })
  } catch (err) {
    alert(`Fail to remove discount: ${err.message}`)
  }
}

export const editDiscount = (discountId, data) => async (dispatch, getState) => {
  try {
    const { data: discounts } = getState().discounts
    const variables = { discountId, request: data }

    const response = await gq.gqRequest(updateDiscount, variables)

    const responseDiscount = response?.update_boorran_Discounts?.returning[0] || false

    if (!responseDiscount) throw Error

    const updatedDiscounts = reduce(
      discounts,
      (result, discount) => {
        if (discount.id === discountId) {
          result.push({ ...responseDiscount, productName: discount.productName })
        } else {
          result.push(discount)
        }

        return result
      },
      []
    )

    dispatch({
      type: PRODUCT_TYPES.UPDATE_DISCOUNT,
      payload: updatedDiscounts,
    })
  } catch (err) {
    alert(`Fail to update discount: ${err.message}`)
  }
}

export const editDiscounts = (discountIds, data) => async (dispatch, getState) => {
  try {
    const { data: discounts } = getState().discounts
    const variables = { discountIds, obj: data }

    const response = await gq.gqRequest(updateDiscounts, variables)

    const responseDiscounts = response?.update_boorran_Discounts?.returning || false

    if (!responseDiscounts) throw Error

    const updatedDiscounts = reduce(
      discounts,
      (result, discount) => {
        const changedDiscount = find(responseDiscounts, (dis) => dis.id === discount.id)

        if (changedDiscount && discount.id === changedDiscount.id) {
          result.push({ ...changedDiscount, productName: discount.productName })
        } else {
          result.push(discount)
        }

        return result
      },
      []
    )

    dispatch({
      type: PRODUCT_TYPES.UPDATE_DISCOUNT,
      payload: updatedDiscounts,
    })
  } catch (err) {
    alert(`Fail to edit discounts: ${err.message}`)
  }
}
