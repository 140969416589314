const qLowStockVariants = `
  {
    boorran_ProductVariants(where: {quantity: {_lte: 10}}) {
      id
      title
      quantity
      Product {
        id
        title
      }
    }
  }
`

const qTodayTopProduct = `
  query($startOfDay: timestamptz, $endOfDay: timestamptz){
    boorran_Orders(where: { createdAt: { _gte: $startOfDay, _lte: $endOfDay } }) {
      orderItems{
        itemTitle
        quantity
      }
    }
  }
`

const qStockConfigs = `
  query {
    boorran_Configs(where: { key: { _eq: "stock" } }) {
      key
      value
    }
  }
`

const qUpdateStockConfigs = `
  mutation ($obj: boorran_Configs_set_input!) {
    update_boorran_Configs(where: {key: {_eq: "stock"}}, _set: $obj) {
      returning {
        key
        value
      }
    }
  }
`

export { qStockConfigs, qLowStockVariants, qTodayTopProduct, qUpdateStockConfigs }
