import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { OutlinedInput, InputLabel, MenuItem, FormControl, Select } from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(value, selectedValue, theme) {
  return {
    fontWeight:
      selectedValue.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

const MultipleSelect = ({
  sx = { mr: 1, width: 200 },
  name = 'Select',
  label = 'Select',
  options = [],
  value = [],
  size = undefined,
  onChange = () => {},
}) => {
  const theme = useTheme()

  const handleChange = (event) => {
    const {
      target: { value: newValue },
    } = event

    onChange(name, typeof newValue === 'string' ? newValue.split(',') : newValue)
  }

  return (
    <FormControl sx={sx}>
      <InputLabel id={`${label}-multiple-name-label`}>{label}</InputLabel>
      <Select
        size={size}
        labelId={`${label}-multiple-name-label`}
        id={`${label}-multiple-name-label`}
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label="Name" />}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option} style={getStyles(option, value, theme)}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MultipleSelect
