/* eslint-disable no-undef */
import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Wrapper } from '@googlemaps/react-wrapper'
import { createCustomEqual } from 'fast-equals'

import Autocomplete from './Autocomplete'
import Marker from './Marker'
import Circle from './Circle'

const mapApiKey = process.env.REACT_APP_GOOGLE_API_KEY || 'AIzaSyBwPfQ9QX2b3JLQoomU7TtBy-kUDXi6cTw'

const GoogleMap = ({
  location,
  onLocationChange,
  onPlaceSearched,
  onMounted,
  height,
  circleRadius,
  myLocation,
}) => {
  const { defaultLocation } = useSelector((store) => store.settings)

  const [zoom, setZoom] = useState(15)
  const [center, setCenter] = useState(defaultLocation)

  useEffect(() => {
    if (onMounted) onMounted({ setCenter })
  }, [])

  const onIdle = (m) => {
    setCenter(m.getCenter().toJSON())
    setZoom(m.getZoom())
  }

  const onPlaceSelected = (place) => {
    onPlaceSearched(place)
    setCenter(place.position)
  }

  const handleLocationChange = (event) => {
    if (onLocationChange) {
      onLocationChange({ lat: event.latLng.lat(), lng: event.latLng.lng() })
    }
  }

  return (
    <div style={{ display: 'flex', height: '100%', position: 'relative' }}>
      <Wrapper apiKey={mapApiKey}>
        <Map
          center={center}
          onClick={handleLocationChange}
          onIdle={onIdle}
          zoom={zoom}
          style={{ flexGrow: '1', height: height || 400 }}
          streetViewControl={false}
          mapTypeControl={false}
          onPlaceSearched={onPlaceSearched && onPlaceSelected}
        >
          {location && <Marker position={location} />}
          {location && circleRadius && <Circle radius={circleRadius} position={location} />}
          {myLocation && <Marker currentMarker position={myLocation} />}
        </Map>
      </Wrapper>
    </div>
  )
}

const Map = ({ onClick, onIdle, children, style, onPlaceSearched, ...options }) => {
  const mapRef = useRef(null)

  const [map, setMap] = useState()

  useEffect(() => {
    if (mapRef.current && !map) {
      setMap(new window.google.maps.Map(mapRef.current, {}))
    }
  }, [mapRef, map])

  useEffect(() => {
    if (map) {
      ;['click', 'idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName))

      if (onClick) {
        map.addListener('click', onClick)
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map))
      }
    }
  }, [map, onClick, onIdle])

  useDeepCompareEffectForMaps(() => {
    if (map) map.setOptions(options)
  }, [map, options])

  return (
    <>
      <div ref={mapRef} style={style} />
      {!!onPlaceSearched && <Autocomplete onPlaceSelected={onPlaceSearched} />}

      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map })
        }

        return null
      })}
    </>
  )
}

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => deepEqual(a, b))

function useDeepCompareMemoize(value) {
  const ref = React.useRef()

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize))
}

export default GoogleMap
