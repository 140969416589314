import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Box,
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'

import Modules from './AccessibleModules'

const SideMenu = React.memo(() => {
  const location = useLocation()
  const {
    auth: { data = {} },
  } = useSelector((store) => store)

  const [open, setOpen] = useState(true)

  const checkActiveMenu = (route) => location.pathname.includes(route)

  return (
    <Box flex={1} p={2}>
      <Box width={210}>
        <List>
          {data.role === 'admin' &&
            Modules[data.role].map((item) => (
              <ListItemButton
                key={item.label}
                to={item.link}
                component={Link}
                selected={checkActiveMenu(item.selected)}
              >
                <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                />
              </ListItemButton>
            ))}

          {data.role === 'sales' &&
            Modules.sales.map((item) => (
              <ListItemButton
                key={item.label}
                to={item.link}
                component={Link}
                selected={checkActiveMenu(item.selected)}
              >
                <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                />
              </ListItemButton>
            ))}

          {['driver', 'vendor'].includes(data.role) &&
            Modules.userAccess.map((item) => (
              <ListItemButton
                key={item.label}
                to={item.link}
                component={Link}
                selected={checkActiveMenu(item.selected)}
              >
                <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                />
              </ListItemButton>
            ))}

          {['finance'].includes(data.role) &&
            Modules.userFinance.map((item) => (
              <ListItemButton
                key={item.label}
                to={item.link}
                component={Link}
                selected={checkActiveMenu(item.selected)}
              >
                <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                />
              </ListItemButton>
            ))}

          {data.role === 'manager' &&
            Modules.manager.map((item) => (
              <ListItemButton
                key={item.label}
                to={item.link}
                component={Link}
                selected={checkActiveMenu(item.selected)}
              >
                <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                />
              </ListItemButton>
            ))}

          {data.role === 'admin' && (
            <Box>
              <Divider />
              <ListItem disablePadding>
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => setOpen(!open)}
                  sx={{
                    px: 9,
                    pt: 2.5,
                    pb: open ? 0 : 2.5,
                    '&:hover, &:focus': { '& svg': { opacity: open ? 1 : 0 } },
                  }}
                >
                  <ListItemText
                    primary="Setting"
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: 'medium',
                      lineHeight: '20px',
                      mb: '2px',
                    }}
                    secondary="Authentication, Firestore Database, Realtime Database, Storage, Hosting, Functions, and Machine Learning"
                    secondaryTypographyProps={{
                      noWrap: true,
                      fontSize: 12,
                      lineHeight: '16px',
                      color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
                    }}
                    sx={{ my: 0 }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      opacity: 0,
                      transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                      transition: '0.2s',
                    }}
                  />
                </ListItemButton>
              </ListItem>

              {open &&
                Modules.settings.map((item) => (
                  <ListItem disablePadding>
                    <ListItemButton
                      key={item.label}
                      to={item.link}
                      component={Link}
                      selected={checkActiveMenu(item.selected)}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
                      <ListItemText
                        primary={item.label}
                        primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
            </Box>
          )}
        </List>
      </Box>
    </Box>
  )
})

export default SideMenu
