const qDuration = (from, end) => `
_and: [
  {createdAt: {_gte: "${from}"}},
  {createdAt: {_lte: "${end}"}}
]`

// const qReceived = 'paymentReceiverId: {_is_null: false}'
const qReceived = 'isCollectedByAdmin: {_eq: false}'
const qCollectedByAdmin = 'isCollectedByAdmin: {_eq: true}'
const qUnpaid = 'status: {_eq: "unpaid"}'
const qPaid = 'status: {_eq: "paid"}'
const qCancelled = 'status: {_eq: "cancelled"}'
const qExchanged = 'status: {_eq: "exchanged"}'
const qPending = 'status: {_eq: "pending"}'
const qNotCancel = 'status: {_neq: "cancelled"}'

// count sales within date time
const qCountSales = (from, end) => `
query {
  count: boorran_Orders_aggregate(where: {
    ${qDuration(from, end)}
    ${qNotCancel}
  }) {
    aggregate {
      count
    }
  }
}
`
// count orders that admin have already collected
const qCountSalesByAdmin = (from, end) => `
query {
  count: boorran_Logs_aggregate(where:
  {
    field: {_eq: "isCollectedByAdmin"},
    value: {_eq: "true"},
    _and: [
      {updated_at: {_gte: "${from}"}},
      {updated_at: {_lte: "${end}"}}
    ]
  }) {
    aggregate {
      count
    }
  }
}
`
// get orders that admin have already collected
const qOrderIdsSalesByAdmin = (from, end) => `
query{
  boorran_Logs(where:
    {
      field: {_eq: "isCollectedByAdmin"},
      value: {_eq: "true"},
      _and: [
        {updated_at: {_gte: "${from}"}},
        {updated_at: {_lte: "${end}"}}
      ]
    }) {
      orderId
    }
}
`

// const qCountSalesByCheth = (from, end) => `
// query {
//   count: boorran_Orders_aggregate(where: {
//     ${qDuration(from, end)}
//     ${qPaid}
//     ${qCollectedByAdmin}
//     ${qCheth}
//   }) {
//     aggregate {
//       count
//     }
//   }
// }
// `

// const qCountSalesByCheth1 = (from, end) => `
// query {
//   count: boorran_Orders_aggregate(where: {
//     ${qDuration(from, end)}
//     ${qNotCancel}
//     ${qCheth}
//   }) {
//     aggregate {
//       count
//     }
//   }
// }
// `
/* count orders that have been updated to paid
 * (including admin collected) within date time
 */
const qCountReceived = (from, end) => `
query {
  count: boorran_Logs_aggregate(where:
  {
    field: {_eq: "status"},
    value: {_eq: "paid"},
    _and: [
      {updated_at: {_gte: "${from}"}},
      {updated_at: {_lte: "${end}"}}
    ]
  }) {
    aggregate {
      count
    }
  }
}
`
/* get orders that have been updated to paid
 * (including admin collected) within date time
 */
const qOrderIdsReceived = (from, end) => `
query{
  boorran_Logs(where:
    {
      field: {_eq: "status"},
      value: {_eq: "paid"},
      _and: [
        {updated_at: {_gte: "${from}"}},
        {updated_at: {_lte: "${end}"}}
      ]
    }) {
      orderId
    }
}
`
// count orders that are changed to unpaid within date time
const qCountUnpaid = (from, end) => `
query {
  count: boorran_Logs_aggregate(where:
  {
    field: {_eq: "status"},
    value: {_eq: "unpaid"},
    _and: [
      {updated_at: {_gte: "${from}"}},
      {updated_at: {_lte: "${end}"}}
    ]
  }) {
    aggregate {
      count
    }
  }
}
`
// get orders that are changed to unpaid within date time
const qOrderIdsUnpaid = (from, end) => `
query {
  count: boorran_Logs(where:
  {
    field: {_eq: "status"},
    value: {_eq: "unpaid"},
    _and: [
      {updated_at: {_gte: "${from}"}},
      {updated_at: {_lte: "${end}"}}
    ]
  }) {
    orderId
  }
}
`

// count orders that has been updated to cancelled within date time
const qCountCancelled = (from, end) => `
query {
  count: boorran_Logs_aggregate(where:
  {
    field: {_eq: "status"},
    value: {_eq: "cancelled"},
    _and: [
      {updated_at: {_gte: "${from}"}},
      {updated_at: {_lte: "${end}"}}
    ]
  }) {
    aggregate {
      count
    }
  }
}
`
// get orders that has been updated to cancelled within date time
const qOrderIdsCancelled = (from, end) => `
query {
  count: boorran_Logs(where:
  {
    field: {_eq: "status"},
    value: {_eq: "cancelled"},
    _and: [
      {updated_at: {_gte: "${from}"}},
      {updated_at: {_lte: "${end}"}}
    ]
  }) {
    orderId
  }
}
`
// count orders that have been updated to exchanged within date time
const qCountExchanged = (from, end) => `
query {
  count: boorran_Logs_aggregate(where:
  {
    field: {_eq: "status"},
    value: {_eq: "exchanged"},
    _and: [
      {updated_at: {_gte: "${from}"}},
      {updated_at: {_lte: "${end}"}}
    ]
  }) {
    aggregate {
      count
    }
  }
}
`
// get orders that have been updated to exchanged within date time
const qOrderIdsExchanged = (from, end) => `
query {
  count: boorran_Logs(where:
  {
    field: {_eq: "status"},
    value: {_eq: "exchanged"},
    _and: [
      {updated_at: {_gte: "${from}"}},
      {updated_at: {_lte: "${end}"}}
    ]
  }) {
    orderId
  }
}
`

// count orders that is pending
const qCountPending = (from, end) => `
query {
  count: boorran_Orders_aggregate(where: {
    ${qDuration(from, end)}
    ${qPending}
  }) {
    aggregate {
      count
    }
  }
}
`
// get sales within date time
const qSubscribeTotalSales = (from, end) => `
query {
  sales: boorran_Orders(where: {
    ${qDuration(from, end)}
    ${qNotCancel}
  }) {
    id
    boorranGrandTotal
    status
    isCollectedByAdmin
    deliveryStatus
    staff { name }
  }
}
`
// get orders that has been collect by admin
const qSubscribeTotalSalesByAdmin = (orderIds) => `
  query {
    boorran_Orders(where: {
      id: {_in: [${orderIds}]}
    }) {
      status
      boorranGrandTotal
      id
    }
  }
`

// const qSubscribeTotalSalesByCheth = (from, end) => `
// query {
//   sales: boorran_Orders(where: {
//     ${qDuration(from, end)}
//     ${qPaid}
//     ${qCollectedByAdmin}
//     ${qCheth}
//   }) {
//     id
//     boorranGrandTotal
//     status
//   }
// }
// `

// const qSubscribeTotalSalesByCheth1 = (from, end) => `
// query {
//   sales: boorran_Orders(where: {
//     ${qDuration(from, end)}
//     ${qNotCancel}
//     ${qCheth}
//   }) {
//     id
//     boorranGrandTotal
//     status
//   }
// }
// `

const qSubscribeReceived = (from, end) => `
query {
  received: boorran_Orders(where: {
    ${qDuration(from, end)}
    ${qReceived}
    ${qPaid}
  }) {
    id
    boorranGrandTotal
  }
}
`

const qSubscribeUnpaid = (from, end) => `
query {
  unpaid: boorran_Orders(where: {
    ${qDuration(from, end)}
    ${qUnpaid}
  }) {
    id
    boorranGrandTotal
  }
}
`

const qSubscribeCancelled = (from, end) => `
query {
  unpaid: boorran_Orders(where: {
    ${qDuration(from, end)}
    ${qCancelled}
  }) {
    id
    boorranGrandTotal
  }
}
`

const qSubscribeExchanged = (from, end) => `
query {
  unpaid: boorran_Orders(where: {
    ${qDuration(from, end)}
    ${qExchanged}
  }) {
    id
    boorranGrandTotal
  }
}
`

const qSubscribePending = (from, end) => `
query {
  unpaid: boorran_Orders(where: {
    ${qDuration(from, end)}
    ${qPending}
  }) {
    id
    boorranGrandTotal
  }
}
`

const qGetMatchedOrderId = (orders) => `
query {
  boorran_Orders(where: {
    id: {_in: [${orders}]}
  }) {
    status
    boorranGrandTotal
    id
  }
}
`

export {
  qCountSales,
  qCountSalesByAdmin,
  qOrderIdsSalesByAdmin,
  // qCountSalesByCheth,
  // qCountSalesByCheth1,
  qCountReceived,
  qOrderIdsReceived,
  qCountUnpaid,
  qOrderIdsUnpaid,
  qCountCancelled,
  qOrderIdsCancelled,
  qCountExchanged,
  qOrderIdsExchanged,
  qCountPending,
  qSubscribeTotalSales,
  qSubscribeTotalSalesByAdmin,
  // qSubscribeTotalSalesByCheth,
  // qSubscribeTotalSalesByCheth1,
  qSubscribeReceived,
  qSubscribeUnpaid,
  qSubscribeCancelled,
  qSubscribeExchanged,
  qSubscribePending,
  qGetMatchedOrderId,
}
