import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Button, Tooltip, Typography, Paper, TextField, Autocomplete } from '@mui/material'
import { searchCustomers } from '../../../redux/actions'

const SearchCustomer = ({ onChange, onAddNewClick }) => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')

  const { search: searchedCustomers } = useSelector((state) => state.customers)

  const handleSearchCustomer = (val) => {
    if (val.length < 2) return null

    const text = val.replace(/^\+?(0)/, '+855')

    setSearchText(text)
    return dispatch(searchCustomers({ search: text }))
  }

  const customers = searchedCustomers.map((customer) => ({
    id: customer.id,
    phone: customer.phone,
    shopifyCustomerId: customer.shopifyCustomerId,
    title: [customer.firstName, customer.lastName].filter(Boolean).join(' '),
  }))

  return (
    <Paper sx={{ p: 2, bgcolor: 'background.default', color: 'text.primary' }}>
      <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">Find or create a customer</Typography>
        </Grid>

        <Grid item sm={9} xs={9}>
          <Autocomplete
            freeSolo
            size="small"
            id="search-customer"
            options={customers}
            value={searchText}
            filterOptions={(options) => options}
            getOptionLabel={(option) => option.title || option}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.title}
              </li>
            )}
            onChange={(event, value) => typeof value !== 'string' && onChange(value)}
            renderInput={(props) => (
              <TextField
                {...props}
                size="small"
                label="Search Customer"
                onChange={(e) => handleSearchCustomer(e.target.value)}
              />
            )}
          />
        </Grid>

        <Grid item sm={3}>
          <Tooltip title="Add New Customer">
            <Button
              variant="outlined"
              aria-label="Add New Customer"
              onClick={onAddNewClick}
              sx={{ bgcolor: 'background.default', color: 'text.primary' }}
            >
              Add
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default React.memo(SearchCustomer)
