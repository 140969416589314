/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import { wsGQLSubscribe, wsGQLUnsubscribe } from 'fetchier'
import gq from '../queries'
import { HASURA_WSS } from '../config'

import { SHIPPING_TYPES } from '../reducers/shipping'

import {
  qShippingCancelList,
  aggregateShippingList,
  updateCancelShipping,
} from '../../queries/shipping'

import { updateOrderByShopifyOrderNumber } from '../../queries/orders'

const debug = false

export const fetchShippings =
  (opts = {}) =>
  (dispatch) => {
    let { limit = 20, offset, page = 1 } = opts || {}
    offset = (page && parseInt(page - 1, 10) * limit) || offset || 0
    const action = (shipping) => {
      gq.gqRequest(aggregateShippingList).then(({ boorran_CancelRequests_aggregate }) => {
        dispatch({
          type: SHIPPING_TYPES.SHIPPINGS_FETCHED,
          payload: {
            limit,
            offset,
            page,
            pages: parseInt(boorran_CancelRequests_aggregate.aggregate.count / limit, 10) + 1,
            data: { shipping, shippingCount: boorran_CancelRequests_aggregate },
          },
        })
      })
    }

    wsGQLUnsubscribe({ url: HASURA_WSS, id: 'cancel-shipping', debug })

    const subscription = {
      id: 'cancel-shipping',
      query: qShippingCancelList(),
      variables: { limit, offset },
      action,
    }
    return wsGQLSubscribe({ url: HASURA_WSS, subscription, debug })
  }

export const rescheduleCancelShipping = async (payload) => {
  try {
    const { id, userId, orderNumber, deliveryStatus, approved, reappointDateTime } = payload
    await gq.gqRequest(updateCancelShipping, {
      id,
      changes: { approved, reappointDateTime, approverId: userId },
    })
    await gq.gqRequest(updateOrderByShopifyOrderNumber, {
      orderNumber,
      changes: { deliveryStatus, deliveryDate: reappointDateTime },
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}
