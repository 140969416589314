/* eslint-disable prefer-const */
import { GQL, wsGQLSubscribe, wsGQLUnsubscribe } from 'fetchier'
import Cookie from 'js-cookie'
import { sumBy } from 'lodash'
import { HASURA_WSS, HASURA_URL, formatEnums } from '../config'
import { DISBURSEMENT_TYPES } from '../reducers/disbursements'
import { SETTINGS_TYPES } from '../reducers/settings'
import { calculateOrderGrandTotal } from '../../utils/format'
import gq from '../queries'
import { getCompletedDisbursementCount, getDisbursements } from '../../queries/disbursement'

const debug = false

const setLoading = (bool) => async (dispatch) =>
  dispatch({ type: DISBURSEMENT_TYPES.SET_LOADING, payload: bool })

export const errordisbursements = (err) => async (dispatch) => {
  dispatch({ type: DISBURSEMENT_TYPES.ERROR, payload: err })
  console.error('Error with orders', err)
  dispatch(setLoading(false))
}

export const infodisbursements = (obj) => async (dispatch) => {
  dispatch({ type: DISBURSEMENT_TYPES.INFO, payload: obj })
}

export const addEnum = (props) => async (dispatch) => {
  const { value, name } = props
  const obj = { type: name, text: value, value }

  gq.gqRequest(gq.qUpsertEnum, { obj })
    .then(() => dispatch(setEnums()))
    .catch((err) => dispatch(errordisbursements(err)))
}

export const setEnums = () => async (dispatch) => {
  dispatch(fetchEnums())
    .then(({ Enums }) => {
      dispatch({
        type: SETTINGS_TYPES.SETTINGS_FETCHED,
        payload: Enums.filter((item) => item.type === 'paymentMethod'),
      })
      dispatch({
        type: DISBURSEMENT_TYPES.ENUMS_FETCHED,
        payload: formatEnums([...Enums]),
      })
    })
    .catch((err) => dispatch(errordisbursements(err)))
}

export const fetchEnums = () => gq.gqRequest(gq.qFetchEnums)

export const fetchUsers = () => gq.gqRequest(gq.qFetchUsers)

export const fetchDisbursements = (payload) => async (dispatch, getState) => {
  const {
    auth,
    disbursements: { pagination, disbursements: navigate },
  } = getState()
  const { id: staffId } = auth.data

  const { page = 1, accepted = false } = payload || {}
  const limit = 20
  const offset = (page - 1) * limit

  let pages = null

  if (page === 1 || page > pagination.pages) {
    const { disbursementsAggregate } = await gq.gqRequest(getCompletedDisbursementCount, {
      staffId,
    })
    const { count } = disbursementsAggregate.aggregate

    pages = Math.ceil(count / limit)
  }

  const { disbursements } = await gq.gqRequest(getDisbursements, {
    staffId,
    limit,
    offset,
    accepted,
  })
  const key = accepted ? 'finishedDisbursements' : 'pendingDisbursements'

  dispatch({
    type: DISBURSEMENT_TYPES.DISBURSEMENTS_navigate_FETCHED,
    payload: {
      limit,
      offset,
      page,
      pages,
      data: { ...navigate, [key]: disbursements },
    },
  })
}

export const fetchDisbursment =
  ({ id, codPage = 1, deliveryPage = 1 }) =>
  async (dispatch, getState) => {
    const { data: auth } = getState().auth

    if (!auth) return

    const numberPerPage = 20
    const codOffset = (codPage - 1) * numberPerPage
    const deliveryOffset = (deliveryPage - 1) * numberPerPage

    const fields = `
    id createdAt paymentType paymentMethod accepted acceptorId requestorId acceptor { id name role } requestor { id name role } transfered note

    paymentReceiver: subDisbursements(
      where: { paymentReceiverId: { _is_null: false }}
      limit: ${numberPerPage}
      offset: ${codOffset}
    ) {
      id paymentReceiver{ name } orderId paymentMethod total disbursmentId shopifyOrderNumber shopifyOrderId
      order { status grandTotal OriginalOrder { grandTotal shippingPrice: deliveryPrice shopifyOrderNumber id } }
    }

    refundByList: subDisbursements(
      where: { refundById: { _is_null: false }}
      limit: ${numberPerPage}
      offset: ${deliveryOffset}
    ){
      id refundBy { name } orderId paymentMethod driverCharge shippingPrice disbursmentId shopifyOrderNumber shopifyOrderId
      order { status }
    }

    refundByAggregate: subDisbursements_aggregate(where: { refundById: { _is_null: false }}){
      aggregate { count sum { shippingPrice } }
    }

    paymentReceiverAggregate: subDisbursements_aggregate(where: { paymentReceiverId: { _is_null: false }}){
      aggregate { count sum { total } }
    }
  `

    const query = `
    query($staffId: uuid $id: Int){
      boorran_Disbursements(where: {
        _and: [
          {_or: [
            { requestorId: { _eq: $staffId}}
            { acceptorId: { _eq: $staffId}}
          ]}
          {id: { _eq: $id}}
        ]
      }){ ${fields} }
    }
  `

    const sessionToken = Cookie.get('sessionToken')
    const {
      boorran_Disbursements: [disbursement],
    } = await GQL({
      url: HASURA_URL,
      query,
      variables: { staffId: auth.id, id },
      headers: { Authorization: sessionToken },
    })

    dispatch({
      type: DISBURSEMENT_TYPES.DISBURSEMENT_navigate_FETCHED,
      payload: {
        data: disbursement
          ? {
              ...disbursement,
              refundByCount: disbursement.refundByAggregate?.aggregate?.count || 0,
              paymentReceiverCount: disbursement.paymentReceiverAggregate?.aggregate?.count || 0,
              deliveryExpense: disbursement.refundByAggregate?.aggregate?.sum?.shippingPrice || 0,
              income: disbursement?.paymentReceiverAggregate?.aggregate?.sum?.total || 0,
            }
          : {},
      },
    })
  }

const getOrderIdsByDisbursementId = async (id) => {
  const query = `
    query($id: Int){
      boorran_Disbursements(where: {id: {_eq: $id}}){
        paymentReceiver: subDisbursements(where: {paymentReceiverId: {_is_null: false}}) {
          orderId
        }

        refundBy: subDisbursements(where: {refundById: {_is_null: false}}){
          orderId
        }
      }
    }
  `

  const result = await GQL({
    url: HASURA_URL,
    query,
    variables: { id },
    headers: { Authorization: Cookie.get('sessionToken') },
  })

  const {
    boorran_Disbursements: [disbursement],
  } = result

  return {
    paymentOrderIds: disbursement?.paymentReceiver?.map(({ orderId }) => orderId),
    refundOrderIds: disbursement?.refundBy?.map(({ orderId }) => orderId) || [],
  }
}

export const cancelDisbursment =
  ({ id }) =>
  async (dispatch) => {
    if (!id) return dispatch(infodisbursements({ message: 'cancel failed' }))

    dispatch(setLoading(true))

    const subDisbursementIds = await getOrderIdsByDisbursementId(id)
    console.log(subDisbursementIds)
    const orderIds = [subDisbursementIds.paymentOrderIds, subDisbursementIds.refundOrderIds].flat()

    const mutation = `
    mutation{
      delete_boorran_Disbursements(where: { id: {_eq: "${id}"}}) {
        affected_rows
      }

      update_boorran_Orders(
        where: { id: { _in: ${JSON.stringify(orderIds)}}}
        _set: {
          paymentRequested: false
          refundRequested: false
        }
      ){ affected_rows }
    }
  `

    const res = await GQL({
      url: HASURA_URL,
      query: mutation,
      headers: { Authorization: Cookie.get('sessionToken') },
    })

    dispatch(infodisbursements({ res, message: 'Cancel successfully' }))
    return dispatch(setLoading(false))
  }

const getUnsettleDisbursement = async (filters, pagination) => {
  const { staff, paymentType, refundIds, codPage = 1, deliveryPage = 1 } = filters
  const { key: staffId, role } = staff || {}

  const numberPerPage = pagination ? 1000 : null
  const codOffset = pagination ? (codPage - 1) * numberPerPage : null
  const deliveryOffset = pagination ? (deliveryPage - 1) * numberPerPage : null

  const orderFields = `
    id
    orderId: id
    shopifyOrderNumber
    shopifyOrderId
    staffId
    subTotal
    status
    grandTotal
    createdAt
    paymentMethod
    deliveryPrice
    boorranDeliveryPrice
    isCollectedByAdmin
    staff { name }
    OriginalOrder { grandTotal deliveryPrice shopifyOrderNumber id }
  `

  const paymentTypeFilter =
    paymentType === 'bank'
      ? '{ paymentMethod: { _in: ["ABA", "ToanChet", "Pi Pay", "Wing SME", "Wing Account"] }}'
      : '{ paymentMethod: { _nin: ["ABA", "ToanChet", "Pi Pay", "Wing SME", "Wing Account"] }}'

  const paymentMethodFilter =
    role === 'driver' ? `{ paymentMethod: { _in: [" Cash on Delivery (COD)"] }}` : paymentTypeFilter

  const paymentReceiverFilter = `
    _and: [
      ${paymentMethodFilter}
      { isCollectedByAdmin: { _eq: false }}
      { paymentRequested: { _eq: false }}
      { paymentReceiverId: { _eq: $staffId}}
      { status: { _in: ["paid", "exchanged"]}}
    ]
  `

  const refundFilter = `
    _and: [
      { refundRequested: { _eq: false }}
      { refundById:{ _eq: $staffId }}
      {_or: [
        {refundDeliveryToStaff: { _eq: "not_yet"}}
        ${role === 'driver' ? '{ refundDeliveryToStaff: { _is_null: true}}' : ''}
      ]}
    ]
  `

  const query = `
    query ($staffId: uuid) {
      paymentReceiver: boorran_Orders(order_by: {createdAt: desc} limit: ${numberPerPage} offset: ${codOffset} where: { ${paymentReceiverFilter} }){
        ${orderFields}
        paymentReceiverId
        paymentReceiver { name }
      }

      refundByList: boorran_Orders(order_by: { createdAt: desc } limit: ${numberPerPage} offset: ${deliveryOffset} where: { ${refundFilter} }){
        ${orderFields}
        refundById
        refundBy { name }
      }

      refundByAggregate: boorran_Orders_aggregate(where: { ${refundFilter} }){
        aggregate { count sum { boorranDeliveryPrice } }
      }

      paymentReceiverAggregate: boorran_Orders_aggregate(where: { ${paymentReceiverFilter} }){
        aggregate { count }
        nodes { grandTotal OriginalOrder { grandTotal deliveryPrice } }
      }
    }
  `

  const sessionToken = Cookie.get('sessionToken')
  let response = {}

  if (staffId) {
    response = await GQL({
      url: HASURA_URL,
      query,
      variables: { staffId },
      headers: { Authorization: sessionToken },
    })
  }

  const {
    paymentReceiver = [],
    refundByList = [],
    refundByAggregate = {},
    paymentReceiverAggregate = {},
  } = response

  const filteredRefundByList = refundIds
    ? refundByList.filter((list) => (refundIds || []).includes(list.id))
    : refundByList

  return {
    paymentReceiver,
    refundByList: filteredRefundByList,
    refundByCount: refundByAggregate?.aggregate?.count || 0,
    paymentReceiverCount: paymentReceiverAggregate?.aggregate?.count || 0,
    income: sumBy(paymentReceiverAggregate?.nodes, calculateOrderGrandTotal) || 0,
    deliveryExpense: sumBy(filteredRefundByList, (item) => item.boorranDeliveryPrice || 0),
  }
}

export const getUnsettleDisbursementOrders = (filters) => async (dispatch) => {
  dispatch(setLoading(true))
  const unsettleDisbursement = await getUnsettleDisbursement(filters, true)

  dispatch({
    type: DISBURSEMENT_TYPES.DISBURSEMENTS_FETCHED,
    payload: { data: { orders: unsettleDisbursement } },
  })
}

export const subscribeSingleOrder = (id) => async (dispatch) => {
  if (!id) {
    wsGQLUnsubscribe({ url: HASURA_WSS, id: 'disbursement', debug })
    return dispatch({ type: DISBURSEMENT_TYPES.DISBURSEMENT_FETCHED, payload: {} })
  }

  dispatch(setLoading(true))

  const subscription = {
    id: 'disbursement',
    query: gq.itemIdById(id),
    action: (order) =>
      dispatch({
        type: DISBURSEMENT_TYPES.DISBURSEMENT_FETCHED,
        payload: order[0],
      }),
  }

  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'disbursement', debug })
  return wsGQLSubscribe({ url: HASURA_WSS, subscription, debug })
}

export const updateDisbursement = (data) => async (dispatch, getState) => {
  dispatch(setLoading(true))

  const { data: auth } = getState().auth
  const { staff, action, paymentMethod, id, note, paymentType, refundIds } = data

  if (!auth) return

  const { paymentReceiver, refundByList, income, deliveryExpense } = await getUnsettleDisbursement({
    staff,
    refundIds,
    paymentType,
  })

  const paymentOrderIds = paymentReceiver.map((item) => item.orderId)
  const refundOrderIds = refundByList.map((item) => item.orderId)

  const mutation = `
    mutation ($values: [boorran_Disbursements_insert_input!]!) {
      insert_boorran_Disbursements(
        objects: $values,
        on_conflict: {
          constraint: Disbursements_pkey,
          update_columns: [${action === 'accept' ? 'accepted' : 'updatedAt'}]
        }
      ) { affected_rows }

      update_payment: update_boorran_Orders(
        where: {
          id: { _in: ${JSON.stringify(paymentOrderIds)}}
        }
        _set: {
          paymentRequested: ${action !== 'accept'}
        }
      ) { affected_rows }

      update_refund: update_boorran_Orders(
        where: {
          id: { _in: ${JSON.stringify(refundOrderIds)}}
        }
        _set: {
          refundRequested: ${action !== 'accept'}
        }
      ){ affected_rows }
    }
  `

  const codDisbursements =
    paymentReceiver.map((item) => ({
      orderId: item.id,
      paymentReceiverId: item.paymentReceiverId,
      total: calculateOrderGrandTotal(item),
      shopifyOrderNumber: item.shopifyOrderNumber,
      paymentMethod: item.paymentMethod,
      shopifyOrderId: item.shopifyOrderId,
    })) || []

  const deliveryDisbursements =
    refundByList.map((item) => ({
      orderId: item.id,
      refundById: item.refundById,
      shippingPrice: item.boorranDeliveryPrice,
      driverCharge: item.driverCharge,
      shopifyOrderNumber: item.shopifyOrderNumber,
      paymentMethod: item.paymentMethod,
      shopifyOrderId: item.shopifyOrderId,
    })) || []

  const values =
    id && action === 'accept'
      ? { id, accepted: true }
      : {
          note,
          requestorId: auth.id,
          acceptorId: staff.key,
          paymentMethod,
          paymentType,
          total: income - deliveryExpense,
          subDisbursements: {
            data: codDisbursements.concat(deliveryDisbursements),
          },
        }

  const sessionToken = Cookie.get('sessionToken')

  const res = await GQL({
    url: HASURA_URL,
    headers: { Authorization: sessionToken },
    query: mutation,
    variables: { values },
  })

  if (action === 'accept') {
    const subDisbursementIds = await getOrderIdsByDisbursementId(id)

    const isAdminCollect = staff.role === 'admin' || auth.role === 'admin'
    const paymentReceiverId = staff.role === 'admin' ? staff.key : auth.id
    const refundById = staff.role === 'admin' ? staff.key : auth.id

    const query = `
      mutation {
        update_payment: update_boorran_Orders(
          where: {
            id: { _in: ${JSON.stringify(subDisbursementIds.paymentOrderIds)}}
          }
          _set: {
            isCollectedByAdmin: ${isAdminCollect},
            paymentReceiverId: "${paymentReceiverId}"
            paymentRequested: false
          }
        ){ affected_rows }

        update_refund: update_boorran_Orders(
          where: {
            id: { _in: ${JSON.stringify(subDisbursementIds.refundOrderIds)}}
          }
          _set: {
            refundDeliveryToStaff: "${isAdminCollect ? 'done' : 'not_yet'}",
            refundById: "${refundById}"
            refundRequested: false
          }
        ){ affected_rows }
      }
    `

    await GQL({
      url: HASURA_URL,
      headers: { Authorization: sessionToken },
      query,
    })

    dispatch(infodisbursements({ res, message: 'Request successfully' }))
    dispatch(setLoading(false))
  }
}

export const subscribeSingleOrderActivityLog = (orderId) =>
  gq.gqRequest(gq.qActivityLog({ orderId })).then(({ boorran_Logs: logs }) => logs)

export const markAsTransfered = (id) => async (dispatch) => {
  dispatch(setLoading(true))

  const sessionToken = Cookie.get('sessionToken')

  const mutation = `mutation {
    update_boorran_Disbursements(where: { id: { _eq: "${id}" } } _set: { transfered: true }) {
      affected_rows
    }
  }`

  await GQL({
    url: HASURA_URL,
    headers: { Authorization: sessionToken },
    query: mutation,
  })

  dispatch(setLoading(false))
}
