import { gql } from '@apollo/client'

const fields = `
  id
  firstName
  lastName
  email
  phone
  shopifyCustomerId
`

const orderAmountFields = `
  id
  subTotal
  taxPrice
  grandTotal
  deliveryPrice
  boorranGrandTotal
  isCollectedByAdmin
  boorranDeliveryPrice
`

const addressFields = `
  CustomerAddresses {
    id
    shopifyAddressId
    address
    firstName
    lastName
    phone
    isDefault
    city
    location
  }
`

const customerOrderFields = `
  id
  shopifyOrderNumber
  orderAddress
  shopifyOrderId
  status
  createdAt
  customer {
    id
    email
    firstName
    lastName
    phone
    shopifyCustomerId
    address
  }
  boorranGrandTotal
  deliveryStatus
  isCollectedByAdmin
  staff {
    id
    name
    email
    metadata
    createdAt
  }
  chat_url
  note
  deliveryDestination
  refundDeliveryToStaff
  delivererId
  paymentMethodRequests {
    image
    id
    delivererId
    approved
    created_at
    orderId
    paymentMethod
    updated_at
  }
`

const filterCustomer = (keyword) => `
  _or: [
    {firstName: {_ilike: "%${keyword}%"}},
    {lastName: {_ilike: "%${keyword}%"}},
    {phone: {_ilike: "%${keyword}%"}}
    {_and: [
      {firstName: {_ilike: "%${keyword.split(' ')[0]}%"}},
      {lastName: {_ilike: "%${keyword.split(' ')[1]}%"}}
    ]}
    {_and: [
      {firstName: {_ilike: "%${keyword.split(' ')[1]}%"}},
      {lastName: {_ilike: "%${keyword.split(' ')[0]}%"}}
    ]}
  ]
`

const qAggregateCustomers = (keyword) => `
  {
    boorran_Customers_aggregate(where: {${keyword ? filterCustomer(keyword) : ''}}) {
      aggregate {
        count
      }
    }
  }`

const qSubscribeCustomers = (keyword) => `
query ($limit: Int, $offset: Int){
    boorran_Customers(where: { ${
      keyword ? filterCustomer(keyword) : ''
    }}, limit: $limit, offset: $offset, order_by: {phone: asc}) {
      ${fields}
    }
  }
  `

const qSearchCustomersByName = `
  query ($search: String){
    boorran_Customers(
        where:{
          _or:[
            {firstName: {_ilike: $search}}
            {lastName: {_ilike: $search}}
            {phone: {_ilike: $search}}
          ]
        }
      )
      {
        ${fields}
      }
  }
`

const qCustomerbyUUID = `
  query ($id: uuid){
    boorran_Customers(
        where:{
          id: {_eq: $id}
        }
      )
      {
        ${fields}
        ${addressFields}
      }
  }
`
const qCustomerbyId = `
  query ($id: String){
    boorran_Customers(
        where:{
          shopifyCustomerId: {_eq: $id}
        }
      )
      {
        ${fields}
      }
  }
`

const qAggregateCustomersOrders = `
  query($id: uuid, $status: Boolean, $deliveryStatus: String) {
    boorran_Orders_aggregate(
      where: {
        customerId: { _eq: $id } ,
        _or: [
          { isCollectedByAdmin: { _eq: $status } },
          { deliveryStatus: {_eq: $deliveryStatus} }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

const qCustomersOrdersAmount = `
  query($id: uuid, $status: Boolean, $deliveryStatus: String) {
    boorran_Orders(
      where: {
        customerId: { _eq: $id } ,
        _or: [
          { isCollectedByAdmin: { _eq: $status } },
          { deliveryStatus: {_eq: $deliveryStatus} }
        ]
      }
    )
    {
      ${orderAmountFields}
    }
  }
`

const qCustomerOrdersById = `
  query ($id: uuid) {
    boorran_Orders(where:
      {customerId: {_eq: $id} }, order_by: { createdAt: desc })
    {
      ${customerOrderFields}
    }
  }
`

const CUSTOMER_QUERY = gql`
  query ($search: String, $limit: Int) {
    boorran_Customers(
      where: {
        _or: [
          { shopifyCustomerId: { _eq: $search } }
          { firstName: { _ilike: $search } }
          { lastName: { _ilike: $search } }
          { phone: { _ilike: $search } }
        ]
      }
      limit: $limit
    ) {
      id
      phone
      lastName
      firstName
      shopifyCustomerId
    }
  }
`

const MUTATION_CREATE_CUSTOMER = gql`
  mutation customerCreate($input: CustomerInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
    }
  }
`

export {
  CUSTOMER_QUERY,
  MUTATION_CREATE_CUSTOMER,
  qCustomerbyId,
  qCustomerbyUUID,
  qAggregateCustomers,
  qSubscribeCustomers,
  qCustomerOrdersById,
  qSearchCustomersByName,
  qCustomersOrdersAmount,
  qAggregateCustomersOrders,
}
