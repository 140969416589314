/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import { POST, wsGQLSubscribe, wsGQLUnsubscribe } from 'fetchier'
import Cookie from 'js-cookie'
import { HASURA_WSS, NODE_URL } from '../config'
import { USER_TYPES } from '../reducers/users'
import gq from '../queries'
import { qUserById, qAggregateUsers, qSubscribeUsers } from '../../queries/users'

const debug = false

const setLoading = (bool) => async (dispatch) =>
  dispatch({ type: USER_TYPES.SET_LOADING, payload: bool })

const error = (err) => async (dispatch) => {
  console.error('Error with users', err)
  dispatch(setLoading(false))
}

export const subscribeUsers =
  (opts = {}) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    let { limit = 20, offset, page = 1, filters } = opts || {}
    offset = (page && parseInt(page - 1, 10) * limit) || offset || 0

    const action = (users) => {
      gq.gqRequest(qAggregateUsers(filters))
        .then(({ Users_aggregate }) => {
          dispatch({
            type: USER_TYPES.USERS_FETCHED,
            payload: {
              limit,
              offset,
              page,
              pages: parseInt(Users_aggregate.aggregate.count / limit, 10) + 1,
              data: { users, usersCount: Users_aggregate },
            },
          })
        })
        .catch((err) => dispatch(error(err)))
    }

    wsGQLUnsubscribe({ url: HASURA_WSS, id: 'users', debug })

    const subscription = {
      id: 'users',
      query: qSubscribeUsers(filters),
      variables: { limit, offset },
      action,
    }
    return wsGQLSubscribe({ url: HASURA_WSS, subscription, debug })
  }

export const subscribeSingleUser = (id) => async (dispatch, getState) => {
  if (!id) {
    wsGQLUnsubscribe({ url: HASURA_WSS, id: 'user', debug })
    dispatch({ type: USER_TYPES.USER_FETCHED, payload: {} })
    return
  }

  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'user', debug })

  dispatch(setLoading(true))

  const action = (user) => {
    dispatch({ type: USER_TYPES.USER_FETCHED, payload: user[0] })
  }

  const subscription = {
    id: 'user',
    query: gq.itemIdById(
      id,
      'Users',
      'id name phone email role createdAt activationStatus metadata'
    ),
    action,
  }

  wsGQLSubscribe({ url: HASURA_WSS, subscription, debug })
}

export const updateUser = (obj) => async (dispatch, getState) => {
  dispatch(setLoading(true))

  const user = Object.assign(Array.isArray(obj) ? [] : {}, obj)
  // const columns = Object.keys(user[0] || user).join(' ');
  const sessionToken = Cookie.get('sessionToken')
  const req = {
    url: `${NODE_URL}/boorran/user`,
    body: user,
    headers: { Authorization: sessionToken },
  }

  return POST(req)
    .then((res) => {
      dispatch(setLoading(false))
    })
    .catch((err) => {
      dispatch(error(err))
      throw err
    })
  // return new Promise((resolve, reject) => {
  //   gq.gqRequest(gq.qUpsert('Users', columns, 'Users_pkey'), { obj: user })
  //     .then(res => {
  // resolve(res);
  // dispatch(setLoading(false));
  //     }).catch(err => {
  // reject('upsert error', err);
  // dispatch(error(err));
  //     });
  // })
}

export const getUserById = (id) => async (dispatch) => {
  try {
    const { Users } = await gq.gqRequest(qUserById, { id })

    if (!Users) throw new Error('Staff not found')

    return Users[0]
  } catch (err) {
    alert(`Error getting user`, err)
  }
}
