import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { get, startCase } from 'lodash'
import { Check, Cancel } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'

import Input from '../../Input'
import Modal from '../../boorran/Modal'

import Telegram from '../../../services/telegram'
import { GET_TELEGRAM_GROUPS } from '../../../queries/configs'

const ModalPurchaseOrder = ({ open, onClose }) => {
  const [group, setGroup] = useState(null)
  const [subject, setSubject] = useState('')
  const [loading, setLoading] = useState(false)
  const [description, setDescription] = useState('')

  const { data: boorranConfigs } = useQuery(GET_TELEGRAM_GROUPS)

  const telegramGroups = get(boorranConfigs, ['boorran_Configs', [0], 'value'], {})
  const groupsOption = telegramGroups
    ? Object.values(telegramGroups).map((grp, index) => ({
        key: grp,
        value: grp,
        text: startCase(Object.keys(telegramGroups)[index]),
      }))
    : []

  const onSubmit = async () => {
    try {
      setLoading(true)

      const req = await Telegram.sendMessage({
        disable_notification: false,
        parse_mode: 'markdown',
        chat_id: group,
        text: `*Subject*: ${subject}\n*Description*:${description}`,
      })

      if (req.status !== 200) throw req

      onClose()
    } catch (err) {
      setLoading(false)
      alert(err.message)
      console.error(err)
    }

    return null
  }

  const ACTIONS = [
    {
      icon: <Cancel />,
      content: 'Cancel',
      color: 'error',
      action: onClose,
    },
    {
      icon: <Check />,
      content: 'Submit',
      color: 'primary',
      loading,
      action: onSubmit,
      disabled: !subject || !description,
    },
  ]

  return (
    <Modal visible={open} onClose={onClose} actions={ACTIONS}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Quick Purchase Order
      </Typography>

      <Grid item sx={{ mb: 2 }}>
        <Typography sx={{ fontSize: 14 }}>Telegram Group</Typography>
        <Input
          value={group}
          type="dropdown"
          options={groupsOption}
          placeholder="Choose Group"
          action={({ name, value }) => setGroup(value)}
        />
      </Grid>

      <Grid item sx={{ mb: 2 }}>
        <Typography sx={{ fontSize: 14 }}>Subject</Typography>
        <Input
          value={subject}
          placeholder="eg: to vendor name"
          action={({ name, value }) => setSubject(value)}
        />
      </Grid>

      <Grid item sx={{ mb: 2 }}>
        <Typography sx={{ fontSize: 14 }}>Description</Typography>
        <Input
          rows={7}
          type="textarea"
          value={description}
          placeholder="Describe your item that want to order"
          action={({ name, value }) => setDescription(value)}
        />
      </Grid>
    </Modal>
  )
}

export default ModalPurchaseOrder
