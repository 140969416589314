/* eslint-disable import/no-cycle */
import React, { useState } from 'react'
import { TextField, Grid } from '@mui/material'
import { Close, Check } from '@mui/icons-material'
import libphonenumberJs from 'libphonenumber-js'
import utils from '../../../utils'
import { createCustomer } from '../../../redux/actions'
import { Popup, GoogleMap } from '../..'

const ModalCustomer = ({ open = false, onConfirm, onCancel, data }) => {
  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState(data || {})
  const { firstName, lastName, phone, city, address, location } = formState

  const handleConfirm = async () => {
    try {
      setLoading(true)

      const formatPhone = libphonenumberJs(phone, 'KH').number
      const customer = await createCustomer({
        first_name: firstName,
        last_name: lastName,
        phone: formatPhone,
        address,
        city,
        metadata: {
          location: {
            lat: location.lat,
            lng: location.lng,
          },
        },
      })

      onConfirm(customer)
    } catch (err) {
      console.error(err)
      alert('Error:', err.message)
    } finally {
      setLoading(false)
    }
  }

  const handleFormState = (field, value) => {
    setFormState((prev) => ({
      ...prev,
      [field]: field === 'phone' ? value.replace(/^\+?(8550|855|0)/, '0') : value,
    }))
  }

  const onPlaceSearched = (place) => {
    handleFormState('city', place.city)
    handleFormState('address', place.address)
    handleFormState('location', place.position)
  }

  const onLocationChange = async (newLocation) => {
    const place = await utils.map.getGeocodeFromLatLng(newLocation)
    onPlaceSearched(place)
  }

  return (
    <Popup
      closeIcon={{ onClose: onCancel }}
      visible={open}
      center
      contentHeader="Create Customer"
      maxWidth="lg"
      actions={[
        {
          icon: <Close />,
          color: 'error',
          content: 'Cancel',
          action: () => onCancel(),
          disabled: loading,
        },
        {
          icon: <Check />,
          color: 'primary',
          content: 'Confirm',
          action: () => handleConfirm(),
          loading,
          disabled: loading || !phone || !address,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            variant="outlined"
            value={formState?.firstName}
            onChange={({ target }) => handleFormState('firstName', target.value)}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            label="Last Name"
            variant="outlined"
            value={formState?.lastName}
            onChange={({ target }) => handleFormState('lastName', target.value)}
          />
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Phone Number"
            variant="outlined"
            value={formState?.phone}
            onChange={({ target }) => handleFormState('phone', target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <GoogleMap
            height={400}
            location={formState?.location}
            onPlaceSearched={onPlaceSearched}
            onLocationChange={onLocationChange}
          />
        </Grid>
      </Grid>
    </Popup>
  )
}

export default ModalCustomer
