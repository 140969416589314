/* eslint-disable camelcase */
import { wsGQLSubscribe, wsGQLUnsubscribe } from 'fetchier'

import gq from '../queries'
import { HASURA_WSS } from '../config'
import { TRANSACTION_TYPES } from '../reducers/transactions'
import { fields, qSubscribeTransactions, qAggregateTransactions } from '../../queries/transactions'

const setLoading = (bool) => ({
  type: TRANSACTION_TYPES.SET_LOADING,
  payload: bool,
})

const setData = (payload) => ({
  type: TRANSACTION_TYPES.TRANSACTIONS_FETCHED,
  payload,
})

export const getTransactions = (opts) => async (dispatch) => {
  dispatch(setLoading(true))

  const { limit = 20, page = 1 } = opts || {}
  const offset = page ? parseInt(page - 1, 10) * limit : 0

  const action = async (data) => {
    const { boorran_Transactions_aggregate } = await gq.gqRequest(qAggregateTransactions())
    const { count } = boorran_Transactions_aggregate.aggregate
    const pages = Math.ceil(count / limit)

    dispatch(setData({ data, page, limit, offset, pages, count }))
  }

  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'transactions' })

  const subscription = {
    id: 'transactions',
    query: qSubscribeTransactions(),
    variables: { limit, offset },
    action,
  }

  return wsGQLSubscribe({ url: HASURA_WSS, subscription })
}

export const subscribeTransaction = (id) => async (dispatch) => {
  if (!id) {
    wsGQLUnsubscribe({ url: HASURA_WSS, id: 'transaction' })
    dispatch({ type: TRANSACTION_TYPES.TRANSACTION_FETCHED, payload: {} })

    return
  }

  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'transaction' })

  dispatch(setLoading(true))

  const action = (data) => {
    dispatch({ type: TRANSACTION_TYPES.TRANSACTION_FETCHED, payload: data[0] })
  }

  const subscription = {
    id: 'transaction',
    query: gq.itemIdById(id, 'boorran_Transactions', fields),
    action,
  }

  wsGQLSubscribe({ url: HASURA_WSS, subscription })
}

export const updateTransaction = (obj) => async (dispatch) => {
  try {
    dispatch(setLoading(true))

    const data = Object.assign(Array.isArray(obj) ? [] : {}, obj)
    const columns = Object.keys(data[0] || data).join(' ')

    await gq.gqRequest(gq.qUpsert('boorran_Transactions', columns, 'Transactions_pkey'), {
      obj: data,
    })
    dispatch(setLoading(false))
  } catch (error) {
    dispatch(setLoading(false))

    throw error
  }
}

export const updateTransactionReviewed = ({ id, reviewed }) => {
  const query = `mutation {
    update_boorran_Transactions(where: {id: {_eq: "${id}"}}, _set: {reviewed: ${reviewed}}) {
      affected_rows
    }
  }`

  return gq.gqRequest(query)
}
