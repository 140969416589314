import React from 'react'
import { Chip } from '@mui/material'
import { HourglassTop, Autorenew, TaskAlt, DeliveryDining } from '@mui/icons-material'

const Fulfilment = ({ status, sx = {} }) => {
  const color =
    {
      Waiting: 'info',
      Delivering: 'success',
      Done: 'default',
      Pending: 'secondary',
      Cancelled: 'error',
      Exchanged: 'primary',
    }[status] || 'default'

  const icon =
    {
      Waiting: <HourglassTop />,
      Delivering: <DeliveryDining />,
      Done: <TaskAlt />,
      Pending: <Autorenew />,
    }[status] || ''

  return (
    <Chip
      sx={{ textTransform: 'uppercase', ...sx }}
      size="small"
      color={color}
      icon={icon}
      label={status}
    />
  )
}

export default Fulfilment
