import React from 'react'
import { Button } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const BackButton = () => {
  const navigate = useNavigate()

  return (
    <Button
      sx={{ mb: 3 }}
      variant="outlined"
      startIcon={<ArrowBackIos />}
      onClick={() => navigate(-1)}
    >
      BACK
    </Button>
  )
}

export default BackButton
