import React from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'

import utils from '../../utils'

const autocompleteStyles = {
  boxSizing: 'border-box',
  borderColor: 'transparent',
  borderRadius: '3px',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
  fontSize: '14px',
  outline: 'none',
  textOverflow: 'ellipses',
  position: 'absolute',
  width: 'calc(100% - 70px)',
  left: 10,
  height: 40,
  marginTop: 10,
  padding: 10,
}

const Autocomplete = ({ onPlaceSelected }) => {
  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyBwPfQ9QX2b3JLQoomU7TtBy-kUDXi6cTw',
    onPlaceSelected: (place, inputRef) =>
      onPlaceSelected(utils.map.serializeAutocomplete(place, inputRef.value)),
    options: {
      types: ['geocode', 'establishment'],
      componentRestrictions: { country: 'kh' },
    },
  })

  return <input ref={ref} style={autocompleteStyles} />
}

export default Autocomplete
