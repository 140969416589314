import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import {
  Clear,
  Money,
  Check,
  MoneyOff,
  Backpack,
  DeliveryDining,
  PlaylistAddCheck,
} from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { updateOrder } from '../../redux/actions'

const DataGridToolBar = () => {
  const dispatch = useDispatch()
  const [orderSelections, setOrderSelections] = useState([])
  const {
    auth: { data: user },
  } = useSelector((store) => store)

  const handleBulkMark = (ids, condition) =>
    dispatch(updateOrder(ids.map((orderId) => ({ id: orderId, ...condition }))))

  const SELECTION_ACTIONS = [
    {
      color: 'primary',
      content: 'Cancel',
      onPress: () => setOrderSelections([]),
    },
    {
      color: 'info',
      icon: <Money />,
      content: 'Mark as Paid',
      onPress: () => handleBulkMark(orderSelections, { status: 'paid' }),
    },
    {
      color: 'info',
      icon: <DeliveryDining />,
      content: 'Mark as Delivered',
      onPress: () => handleBulkMark(orderSelections, { deliveryStatus: 'Done' }),
    },

    {
      icon: <Check />,
      color: 'success',
      content: 'Mark as Received Payment',
      onPress: () =>
        handleBulkMark(orderSelections, {
          paymentReceiverId: user.id,
          paymentReceivedDate: new Date(),
        }),
    },
    {
      content: 'Mark as Collected',
      onPress: () => handleBulkMark(orderSelections, { isCollectedByAdmin: true }),
      icon: <PlaylistAddCheck />,
      color: 'secondary',
    },
    {
      color: 'error',
      icon: <MoneyOff />,
      content: 'Mark Delivery as Refunded',
      onPress: () => handleBulkMark(orderSelections, { refundDeliveryToStaff: 'done' }),
    },
    {
      color: 'error',
      icon: <MoneyOff />,
      content: 'Mark Refunded by',
      onPress: () => handleBulkMark(orderSelections, { refundById: user.id }),
    },
    {
      color: 'warning',
      icon: <Backpack />,
      content: 'Mark as Packed',
      onPress: () =>
        handleBulkMark(orderSelections, {
          packBy: user.id,
          packStatus: 'Done',
          deliveryStatus: 'Waiting',
        }),
    },
    {
      color: 'error',
      icon: <Clear />,
      content: 'Mark as Fulfillment Cancelled',
      onPress: () =>
        handleBulkMark(orderSelections, {
          packBy: user.id,
          status: 'cancelled',
          refundById: user.id,
          packStatus: 'Cancelled',
          deliveryStatus: 'Cancelled',
          refundDeliveryToStaff: 'done',
        }),
    },
    {
      color: 'error',
      icon: <Clear />,
      content: 'Mark as Fulfillment Exchanged',
      onPress: () =>
        handleBulkMark(orderSelections, {
          packBy: user.id,
          status: 'exchanged',
          refundById: user.id,
          packStatus: 'Exchanged',
          deliveryStatus: 'Exchanged',
          refundDeliveryToStaff: 'done',
        }),
    },
  ]
  const hasPermission = user.role === 'admin'

  return (
    <Box sx={{ p: 1 }}>
      {hasPermission && orderSelections.length > 0
        ? SELECTION_ACTIONS.map((action, index) => (
            <Button
              key={index}
              variant="contained"
              color={action.color}
              startIcon={action.icon || null}
              sx={{ m: 0.5 }}
              onClick={() => {
                action.onPress()
                setTimeout(() => setOrderSelections([]), 500)
              }}
            >
              {action.content}
            </Button>
          ))
        : null}
    </Box>
  )
}
export default DataGridToolBar
