import React from 'react'
import { Box, Modal, Paper } from '@mui/material'
import Button from '@mui/lab/LoadingButton'

const Comp = ({
  actions,
  children,
  style = {},
  modalStyle = {},
  visible = false,
  onClose = console.log,
}) => (
  <Modal
    open={visible}
    onClose={onClose}
    sx={[{ display: 'flex', alignItems: 'center', justifyContent: 'center' }, { ...style }]}
  >
    <Paper
      sx={{
        p: 3,
        width: 400,
        minWidth: 400,
        position: 'relative',
        ...modalStyle,
      }}
    >
      {children}
      {actions && (
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {actions.map((item, key) => (
            <Button
              sx={{
                mb: 2,
                mx: 1,
              }}
              key={key}
              variant="contained"
              color={item.color}
              onClick={item.action}
              startIcon={item.icon}
              disabled={item.disabled}
              loading={item.loading}
              loadingPosition="start"
            >
              {item.content}
            </Button>
          ))}
        </Box>
      )}
    </Paper>
  </Modal>
)

export default Comp
