const getCompletedDisbursementCount = `
  query($staffId: uuid){
    disbursementsAggregate: boorran_Disbursements_aggregate(where: {
      accepted: { _eq: true }
      _or: [
        { requestorId: { _eq: $staffId } },
        { acceptorId: { _eq: $staffId } }
      ]
    }) {
      aggregate { count }
    }
  }
`

const getDisbursements = `
  query ($staffId: uuid, $accepted: Boolean, $limit: Int, $offset: Int) {
    disbursements: boorran_Disbursements(
      where: {accepted: {_eq: $accepted}, _or: [{requestorId: {_eq: $staffId}}, {acceptorId: {_eq: $staffId}}]},
      limit: $limit,
      offset: $offset,
      order_by: {createdAt: desc}
    ) {
      id
      createdAt
      paymentType
      paymentMethod
      accepted
      total
      acceptor {
        id
        name
        role
      }
      requestor {
        id
        name
        role
      }
      transfered
    }
  }
`

export { getCompletedDisbursementCount, getDisbursements }
