const fields = `
  id
  itemId
  title
`
const variantFields = `
  id
  itemId
  title
  price
  imageId
  quantity
`
const imageFields = 'source shopifyImageId'

const qAggregateProducts = () => `
  {
    boorran_Products_aggregate(where: {}) {
      aggregate {
        count
      }
    }
  }`

const qSubscribeProducts = () => `
subscription ($limit: Int, $offset: Int) {
      boorran_Products(where: {}, limit: $limit, offset: $offset, order_by: {createdAt: asc}) {
        ${fields}
        ProductVariants_aggregate {
          aggregate{
            count
          }
        }
      }
    }
  `

const qSearchProductsByTitle = `
  query ($title: String, $limit: Int, $offset: Int) {
    boorran_Products(
      where:{
        _or:[
          {title: {_ilike: $title}}
          {ProductVariants: {title: {_ilike: $title}}}
        ]
      },
      limit: $limit,
      offset: $offset
    )
    {
      itemId
      title
      ProductImages{
        ${imageFields}
      }
      ProductVariants{
        ${variantFields}
      }
    }
  }
`

const qSingleProduct = `
query ($productId: uuid) {
  product: boorran_Products(where:
    {id: {_eq: $productId}}) {
    id
    itemId
    title
    ProductImages {
      source
      shopifyImageId
    }
  }
  variants: boorran_ProductVariants(where:{
    productId: {_eq: $productId}
  }) {
    id
    title
    imageId
    quantity
  }
}
`

const qProductVariantByShopifyId = `
  query($variantId: String) {
    variant: boorran_ProductVariants(where: {itemId: {_eq: $variantId}}) {
      imageId
      stock: quantity
    }
  }
`

const qProductImageByImageId = `
  query($imageId: String){
    image: boorran_ProductImages(where: {shopifyImageId: {_eq: $imageId}}) {
      id
      shopifyImageId
      source
    }
  }
`

const qProductImageByVariantId = `
  query($variantIds: [String!]){
    boorran_ProductVariants(where: {itemId: {_in: $variantIds}}) {
      itemId
      image{
        source
      }
    }
  }
`

const qProductTitleByIds = `
  query ($productIds: [uuid!]!){
    boorran_Products(where: { id: { _in: $productIds } }) {
      id
      title
    }
  }
`

const qProductItemIds = `
  query ($itemIds: [String!]) {
    boorran_Products(where: { itemId: { _in: $itemIds } }) {
      title
      itemId
      ProductVariants {
        ${variantFields}
      }
    }
  }
`

const gqVariantsIdsByProductIds = `
  query($productIds: [String!]) {
    boorran_ProductVariants(where: { Product: { itemId: { _in: $productIds } } }) {
      itemId
      productId
    }
  }
`

export {
  qAggregateProducts,
  qSubscribeProducts,
  qSearchProductsByTitle,
  qSingleProduct,
  qProductTitleByIds,
  qProductVariantByShopifyId,
  qProductImageByImageId,
  qProductImageByVariantId,
  qProductItemIds,
  gqVariantsIdsByProductIds,
}
