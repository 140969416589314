import React from 'react'
import { Typography, Link } from '@mui/material'

const Footer = () => {
  return (
    <Typography py={4} variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://boorran.com/">
        boorran
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default Footer
