/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
import { groupBy } from 'lodash'
import { wsGQLSubscribe, wsGQLUnsubscribe } from 'fetchier'
import { HASURA_WSS } from '../config'
import { REPORT_TYPES } from '../reducers/salesReport'
import {
  qSubscribeTotalSales,
  qCountPending,
  qCountSales,
  qCountSalesByAdmin,
  qOrderIdsSalesByAdmin,
  qCountReceived,
  qCountUnpaid,
  qCountCancelled,
  qCountExchanged,
  qSubscribePending,
  qGetMatchedOrderId,
  qOrderIdsReceived,
  qOrderIdsUnpaid,
  qOrderIdsCancelled,
  qOrderIdsExchanged,
} from '../../queries/salesReports'

const setLoading = (bool) => ({
  type: REPORT_TYPES.SET_LOADING,
  payload: bool,
})

export const subscribeReport =
  ({ from: fromDate, to: toDate }) =>
  async (dispatch) => {
    // convert to UTC time based on DB timestamp -7 hours from local time
    const originFromDate = new Date(fromDate)
    const fromUTC = new Date(originFromDate.setDate(originFromDate.getDate() - 1))
      .toISOString()
      .split('T')[0]
    const [from, to] = [`${fromUTC}T17:00:00.000Z`, `${toDate}T16:59:59.000Z`]

    dispatch(setLoading(true))
    Promise.all([
      dispatch(subscribeTotalSales(from, to)),
      dispatch(subscribeTotalReceived(from, to)),
      dispatch(subscribeTotalUnpaid(from, to)),
      dispatch(subscribeTotalCancelled(from, to)),
      dispatch(subscribeTotalExchanged(from, to)),
      dispatch(subscribeTotalPending(from, to)),
    ]).then(() => dispatch(setLoading(false)))
  }

export const subscribeTotalSales = (from, to) => async (dispatch) => {
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'total-sales' })
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'count-sales' })

  const action = (data) => {
    const sales = data
      ?.reduce((count, current) => (count += current.boorranGrandTotal), 0)
      .toFixed(2)
    const users = groupBy(data, 'staff.name')
    const countSales = {
      id: 'count-sales',
      query: qCountSales(from, to),
      action: (data) => {
        const {
          aggregate: { count },
        } = data
        dispatch({
          type: REPORT_TYPES.SET_TOTAL_SALES,
          payload: { count, sales, users },
        })
      },
    }
    wsGQLSubscribe({ url: HASURA_WSS, subscription: countSales })
  }

  const subscription = {
    id: 'total-sales',
    query: qSubscribeTotalSales(from, to),
    action,
  }

  return wsGQLSubscribe({ url: HASURA_WSS, subscription })
}

export const subscribeTotalSalesByAdmin = (from, to) => async (dispatch) => {
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'total-sales-orders-by-admin' })
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'total-sales-by-admin' })
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'count-sales-by-admin' })

  const action = (data) => {
    const salesByAdmin = data
      ?.reduce((count, current) => (count += current.boorranGrandTotal), 0)
      .toFixed(2)
    const countSalesByAdmin = {
      id: 'count-sales-by-admin',
      query: qCountSalesByAdmin(from, to),
      action: (data) => {
        const {
          aggregate: { count },
        } = data
        dispatch({
          type: REPORT_TYPES.SET_TOTAL_SALES_BY_ADMIN,
          payload: { count, salesByAdmin },
        })
      },
    }
    wsGQLSubscribe({ url: HASURA_WSS, subscription: countSalesByAdmin })
  }

  const subscription = {
    id: 'total-sales-orders-by-admin',
    query: qOrderIdsSalesByAdmin(from, to),
    action: (data) => {
      const orderIds = data.map((item) => `"${item.orderId}"`)
      const totalSalesByAdmin = {
        id: 'total-sales-by-admin',
        query: qGetMatchedOrderId(orderIds),
        action,
      }
      wsGQLSubscribe({ url: HASURA_WSS, subscription: totalSalesByAdmin })
    },
  }

  return wsGQLSubscribe({ url: HASURA_WSS, subscription })
}

export const subscribeTotalReceived = (from, to) => async (dispatch) => {
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'total-order-received' })
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'total-received' })
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'count-received' })

  const action = (data) => {
    const received = data
      ?.reduce((count, current) => (count += current.boorranGrandTotal), 0)
      .toFixed(2)
    const countReceived = {
      id: 'count-received',
      query: qCountReceived(from, to),
      action: (data) => {
        const {
          aggregate: { count },
        } = data
        dispatch({
          type: REPORT_TYPES.SET_TOTAL_RECEIVED,
          payload: { count, received },
        })
      },
    }
    wsGQLSubscribe({ url: HASURA_WSS, subscription: countReceived })
  }

  const subscription = {
    id: 'total-order-received',
    query: qOrderIdsReceived(from, to),
    action: (data) => {
      const orderIds = data.map((item) => `"${item.orderId}"`)
      const totalOrderReceive = {
        id: 'total-received',
        query: qGetMatchedOrderId(orderIds),
        action,
      }
      wsGQLSubscribe({ url: HASURA_WSS, subscription: totalOrderReceive })
    },
  }

  return wsGQLSubscribe({ url: HASURA_WSS, subscription })
}

export const subscribeTotalUnpaid = (from, to) => async (dispatch) => {
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'total-order-unpaid' })
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'total-unpaid' })
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'count-unpaid' })

  const action = (data) => {
    const unpaid = data
      ?.reduce((count, current) => (count += current.boorranGrandTotal), 0)
      .toFixed(2)
    const countUnpaid = {
      id: 'count-unpaid',
      query: qCountUnpaid(from, to),
      action: (data) => {
        const {
          aggregate: { count },
        } = data
        dispatch({
          type: REPORT_TYPES.SET_TOTAL_UNPAID,
          payload: { count, unpaid },
        })
      },
    }
    wsGQLSubscribe({ url: HASURA_WSS, subscription: countUnpaid })
  }

  const subscription = {
    id: 'total-order-unpaid',
    query: qOrderIdsUnpaid(from, to),
    action: (data) => {
      const orderIds = data.map((item) => `"${item.orderId}"`)
      const totalOrderUnpaid = {
        id: 'total-unpaid',
        query: qGetMatchedOrderId(orderIds),
        action,
      }
      wsGQLSubscribe({ url: HASURA_WSS, subscription: totalOrderUnpaid })
    },
  }

  return wsGQLSubscribe({ url: HASURA_WSS, subscription })
}

export const subscribeTotalCancelled = (from, to) => async (dispatch) => {
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'total-order-cancelled' })
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'total-cancelled' })
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'count-cancelled' })

  const action = (data) => {
    const cancelled = data
      ?.reduce((count, current) => (count += current.boorranGrandTotal), 0)
      .toFixed(2)
    const countCancelled = {
      id: 'count-cancelled',
      query: qCountCancelled(from, to),
      action: (data) => {
        const {
          aggregate: { count },
        } = data
        dispatch({
          type: REPORT_TYPES.SET_TOTAL_CANCELLED,
          payload: { count, cancelled },
        })
      },
    }
    wsGQLSubscribe({ url: HASURA_WSS, subscription: countCancelled })
  }

  const subscription = {
    id: 'total-order-cancelled',
    query: qOrderIdsCancelled(from, to),
    action: (data) => {
      const orderIds = data.map((item) => `"${item.orderId}"`)
      const totalOrderCancelled = {
        id: 'total-cancelled',
        query: qGetMatchedOrderId(orderIds),
        action,
      }
      wsGQLSubscribe({ url: HASURA_WSS, subscription: totalOrderCancelled })
    },
  }

  return wsGQLSubscribe({ url: HASURA_WSS, subscription })
}

export const subscribeTotalExchanged = (from, to) => async (dispatch) => {
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'total-order-exchanged' })
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'total-exchanged' })
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'count-exchanged' })

  const action = (data) => {
    const exchanged = data
      ?.reduce((count, current) => (count += current.boorranGrandTotal), 0)
      .toFixed(2)
    const countExchanged = {
      id: 'count-exchanged',
      query: qCountExchanged(from, to),
      action: (data) => {
        const {
          aggregate: { count },
        } = data
        dispatch({
          type: REPORT_TYPES.SET_TOTAL_EXCHANGED,
          payload: { count, exchanged },
        })
      },
    }
    wsGQLSubscribe({ url: HASURA_WSS, subscription: countExchanged })
  }

  const subscription = {
    id: 'total-order-exchanged',
    query: qOrderIdsExchanged(from, to),
    action: (data) => {
      const orderIds = data.map((item) => `"${item.orderId}"`)
      const totalOrderExchanged = {
        id: 'total-exchanged',
        query: qGetMatchedOrderId(orderIds),
        action,
      }
      wsGQLSubscribe({ url: HASURA_WSS, subscription: totalOrderExchanged })
    },
  }

  return wsGQLSubscribe({ url: HASURA_WSS, subscription })
}

export const subscribeTotalPending = (from, to) => async (dispatch) => {
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'total-pending' })
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'count-pending' })

  const action = (data) => {
    const pending = data
      ?.reduce((count, current) => (count += current.boorranGrandTotal), 0)
      .toFixed(2)
    const countPending = {
      id: 'count-pending',
      query: qCountPending(from, to),
      action: (data) => {
        const {
          aggregate: { count },
        } = data
        dispatch({
          type: REPORT_TYPES.SET_TOTAL_PENDING,
          payload: { count, pending },
        })
      },
    }
    wsGQLSubscribe({ url: HASURA_WSS, subscription: countPending })
  }

  const subscription = {
    id: 'total-pending',
    query: qSubscribePending(from, to),
    action,
  }

  return wsGQLSubscribe({ url: HASURA_WSS, subscription })
}
