import React, { useState, } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Autocomplete, TextField, Box, Grid, ListItemText, Divider } from '@mui/material'
import { useLazyQuery, gql } from '@apollo/client'
import { Link as RouterLink } from 'react-router-dom'
import moment from 'moment'
import { Fulfilment, Payment } from './orders'

const QUERY_FILTERS = gql`
  query filters($filters: String) {
    boorran_Orders(
      limit: 10
      order_by: { createdAt: desc }
      where: {
        _or: [
          { shopifyOrderNumber: { _ilike: $filters } }
          {
            customer: {
              _or: [{ firstName: { _ilike: $filters } }, { phone: { _ilike: $filters } }]
            }
          }
        ]
      }
    ) {
      customer {
        firstName
        lastName
        phone
      }
      createdAt
      shopifyOrderNumber
      id
      deliveryStatus
      status
      packStatus
      orderAddress
    }
  }
`
export default function ComboBox() {
  const [filters, setFilters] = useState('')
  const {
    orders,
    auth: { data: user },
  } = useSelector((store) => store)
  const [getLocations, { data, loading }] = useLazyQuery(QUERY_FILTERS, {
    variables: {
      filters: `%${filters}%`,
    },
  })
  // const nodes = data?.boorran_Orders.map()

  // if (loading) return <div>loading</div>
  // if (error) return <div>error</div>
  return (
    <div>
      {/* <Button onClick={() => getLocations()}>Search</Button> */}

      <Autocomplete
        size="small"
        options={data?.boorran_Orders || []}
        getOptionLabel={(option) => [
          option.shopifyOrderNumber,
          option.customer.firstName,
          option.customer.phone,
        ]}
        loading={loading}
        freeSolo
        forcePopupIcon={false}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            value={filters}
            label="Search orders ..."
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setFilters(e.target.value)
                getLocations()
              }
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ margin: 'auto', flexGrow: 1, boxShadow: 1, borderRadius: 1 }}
            {...props}
          >
            {option && (
              <>
                    {user.role !== 'driver' ? (
               <Grid
                wrap="nowrap"
                component={RouterLink}
                to={`/orders/${option.id}`}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={(0, 2)}
              >
                
                <Grid item xs={2}>
                  <ListItemText
                    primary={option.shopifyOrderNumber}
                    secondary={moment(option.createdAt).format('Do MM YYYY, h:mm a')}
                  />
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={1.5}>
                  <ListItemText primary="Status" />
                  <Payment status={option.status} />
                </Grid>
                <Divider orientation="vertical" flexItem />

                <Grid item xs={1.5}>
                  <ListItemText primary="Delivery" />
                  <Fulfilment status={option.deliveryStatus} />
                </Grid>
                <Divider orientation="vertical" flexItem />

                <Grid item xs={6}>
                  <ListItemText
                    primary={option.customer.firstName}
                    secondary={option.orderAddress}
                  />
                  <ListItemText secondary={option.customer.phone} />
                </Grid>
            </Grid>
          ) : null}
          {user.role === 'driver' ? (
               <Grid
                wrap="nowrap"
                component={RouterLink}
                to={`orders?tab=assignedDeliver&id=${option.id}`}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={(0, 2)}
              >
                
                <Grid item xs={2}>
                  <ListItemText
                    primary={option.shopifyOrderNumber}
                    secondary={moment(option.createdAt).format('Do MM YYYY, h:mm a')}
                  />
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={1.5}>
                  <ListItemText primary="Status" />
                  <Payment status={option.status} />
                </Grid>
                <Divider orientation="vertical" flexItem />

                <Grid item xs={1.5}>
                  <ListItemText primary="Delivery" />
                  <Fulfilment status={option.deliveryStatus} />
                </Grid>
                <Divider orientation="vertical" flexItem />

                <Grid item xs={6}>
                  <ListItemText
                    primary={option.customer.firstName}
                    secondary={option.orderAddress}
                  />
                  <ListItemText secondary={option.customer.phone} />
                </Grid>
            </Grid>
          ) : null}
             </>
            )}
          </Box>
        )}
      />
      {/* <input value={name} onChange={(e) => setName(e.target.value)} /> */}
      {/* {loading && <div>loading...</div>}
      {error && <div>error</div>} */}

      {/* {nodes && (
        <ul>
          {nodes.map((i) => {
            return (
              <li>
                <Link to={`/orders/${i.id}`}>{i.name}</Link>
              </li>
            )
          })}
        </ul>
      )} */}
    </div>
  )
}


