const fields = `
  id
  date
  description
  account
  category
  amount
  reviewed
  transaction_type
  created_at
  updated_at
`

const qAggregateTransactions = () => `{
  boorran_Transactions_aggregate(where: {}) {
    aggregate {
      count
    }
  }
}`

const qSubscribeTransactions = () => `subscription($limit: Int, $offset: Int) {
  boorran_Transactions(where: {}, limit: $limit, offset: $offset, order_by: {created_at: desc}) {
    ${fields}
  }
}`

export { fields, qSubscribeTransactions, qAggregateTransactions }
