import reduce from 'lodash/reduce'
import gq from '../queries'
import { STOCKB_TYPES } from '../reducers/stockb'
import {
  qStockConfigs,
  qTodayTopProduct,
  qLowStockVariants,
  qUpdateStockConfigs,
} from '../../queries/stockb'

import { fetchProductByItemIds } from './products'

const fetchLowStockVariants = () => (dispatch) => {
  gq.gqRequest(qLowStockVariants)
    .then(({ boorran_ProductVariants: data }) => {
      dispatch({
        type: STOCKB_TYPES.FETCH_LOW_STOCK_VARIANTS,
        payload: data,
      })
    })
    .catch((e) => console.log('fetch low stock variants', e.message || e))
}

const fetchBestSellers = (startOfDay, endOfDay) => (dispatch) => {
  gq.gqRequest(qTodayTopProduct, { startOfDay, endOfDay })
    .then(({ boorran_Orders: data }) => {
      dispatch({
        type: STOCKB_TYPES.FETCH_BEST_SELLERS,
        payload: data,
      })
    })
    .catch((e) => console.log('fetch best sellers', e.message || e))
}

const fetchStockConfigs = () => async (dispatch) => {
  try {
    const { boorran_Configs: config } = await gq.gqRequest(qStockConfigs)

    const itemIds = reduce(
      config[0].value,
      (result, data) => {
        result.push(...Object.keys(data.products))
        return result
      },
      []
    )
    dispatch(fetchProductByItemIds(itemIds))

    dispatch({
      type: STOCKB_TYPES.SET_STOCK_CONFIGS,
      payload: config[0].value,
    })
  } catch (err) {
    console.log('Fetch stock configs', err.message || err)
  }
}

const updateStockConfigs = (updateObj) => async (dispatch) => {
  try {
    const request = await gq.gqRequest(qUpdateStockConfigs, { obj: updateObj })

    const {
      update_boorran_Configs: { returning },
    } = request

    dispatch({
      type: STOCKB_TYPES.SET_STOCK_CONFIGS,
      payload: returning[0].value,
    })
  } catch (err) {
    console.log('updateStockConfigs', err.message || err)
  }
}

export { fetchStockConfigs, updateStockConfigs, fetchLowStockVariants, fetchBestSellers }
