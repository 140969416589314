import React, { useState } from 'react'
import { Typography, TextField, Grid, Button, Paper, Box } from '@mui/material'
import { Close, Check } from '@mui/icons-material'
import { updateCustomerAddress } from '../../../redux/actions'
import utils from '../../../utils'

import { Popup, GoogleMap } from '../..'

const ModalAddress = ({ onConfirm, onCancel, customer }) => {
  const [formState, setFormState] = useState(null)

  const { CustomerAddresses: addresses } = customer

  const action = Object.keys(formState || {}).length > 0 ? 'Edit' : 'Add'
  const title = formState ? `${action} Address` : 'Shipping Address'

  const handleSetDefault = async (id) => {
    const payload = { id, customerId: customer.id }

    try {
      await updateCustomerAddress(payload, '?setDefault=true')
      onConfirm()
    } catch (error) {
      alert(error.message || error)
    }
  }

  const handleUpdate = async () => {
    const { firstName, lastName, phone, address, location } = formState

    if (!firstName && !lastName) return alert('First Name or Last Name must be exist!')

    if (!phone) return alert('Phone must be exist!')

    if (!address || !location) return alert('Address must be exits!')

    const payload = {
      ...formState,
      location: formState.location ? `${formState.location.lat},${formState.location.lng}` : '',
      customerId: customer.id,
      shopifyCustomerId: customer.shopifyCustomerId,
    }

    try {
      await updateCustomerAddress(payload)
      onConfirm()
      onCancel()
    } catch (error) {
      alert(error.message || error)
    }

    return null
  }

  const handleFormState = (field, value) => {
    setFormState((prev) => ({
      ...prev,
      [field]: field === 'phone' ? value.replace(/^\+?(8550|855|0)/, '0') : value,
    }))
  }

  const onPlaceSearched = (place) => {
    handleFormState('city', place.city)
    handleFormState('address', place.address)
    handleFormState('location', place.position)
  }

  const onLocationChange = async (newLocation) => {
    const place = await utils.map.getGeocodeFromLatLng(newLocation)
    onPlaceSearched(place)
  }

  const editClick = (address) => {
    const defaultLocation = address.location
      ? {
          lat: Number(address.location.split(',')[0]),
          lng: Number(address.location.split(',')[1]),
        }
      : null

    setFormState({
      ...address,
      location: defaultLocation,
    })
  }

  return (
    <Popup
      visible
      actions={[
        { icon: <Close />, color: 'error', content: 'Cancel', action: () => onCancel() },
        {
          icon: <Check />,
          color: 'primary',
          content: 'Confirm',
          action: () => (formState ? handleUpdate() : onCancel()),
        },
      ]}
    >
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      {formState ? (
        <AddressForm
          data={formState}
          handleFormState={handleFormState}
          onPlaceSearched={onPlaceSearched}
          onLocationChange={onLocationChange}
          onMapMounted={(ref) => formState.location && ref.setCenter(formState.location)}
        />
      ) : (
        <AddressList
          addresses={addresses}
          onEditClick={editClick}
          onAddClick={() => setFormState({})}
          handleSetDefault={handleSetDefault}
        />
      )}
    </Popup>
  )
}

const AddressList = ({ addresses = [], handleSetDefault, onEditClick, onAddClick }) => (
  <Box>
    {addresses.map((address, key) => (
      <Paper sx={{ p: 1, mb: 2 }} key={key}>
        <Typography sx={{ fontWeight: 'bold' }}>
          {address.firstName} {address.lastName}
        </Typography>

        <div>Address: {address.address}</div>
        <div>Tel: {address.phone}</div>

        <Box sx={{ mt: 1 }}>
          <Button
            variant="contained"
            onClick={() => onEditClick(address)}
            size="small"
            color="info"
          >
            Edit
          </Button>

          {address.isDefault ? (
            <span style={{ fontWeight: 'bold', marginLeft: 8 }}>Default</span>
          ) : (
            <Button
              variant="outlined"
              onClick={() => handleSetDefault(address.id)}
              size="small"
              sx={{ ml: 1 }}
            >
              Set as Default
            </Button>
          )}
        </Box>
      </Paper>
    ))}

    <Button variant="outlined" onClick={onAddClick} size="small">
      Add New
    </Button>
  </Box>
)

const AddressForm = ({
  data,
  handleFormState,
  onLocationChange,
  onPlaceSearched,
  onMapMounted,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={6}>
      <TextField
        fullWidth
        label="First Name"
        variant="outlined"
        value={data.firstName}
        onChange={({ target }) => handleFormState('firstName', target.value)}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        fullWidth
        label="Last Name"
        variant="outlined"
        value={data.lastName}
        onChange={({ target }) => handleFormState('lastName', target.value)}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth
        label="Phone Number"
        variant="outlined"
        value={data.phone}
        onChange={({ target }) => handleFormState('phone', target.value)}
      />
    </Grid>

    <Grid item xs={12}>
      <GoogleMap
        onMounted={onMapMounted}
        height={200}
        location={data.location}
        onPlaceSearched={onPlaceSearched}
        onLocationChange={onLocationChange}
      />
    </Grid>
  </Grid>
)

export default ModalAddress
