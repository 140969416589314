const formatDate = (isoString) => {
  const options = {
    hour12: true,
    day: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
  }
  const today = new Date()
  const orderDate = new Date(isoString)
  const dateString = orderDate.toLocaleDateString('en-US', options).split(',')

  if (orderDate.getDate() === today.getDate()) dateString[0] = 'Today'

  if (orderDate.getDate() === today.getDate() - 1) dateString[0] = 'Yesterday'

  return dateString.join(',')
}

export default formatDate
