import React, { useRef, useEffect } from 'react'
import { Button } from '@mui/material'
import { Delete } from '@mui/icons-material'

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  placeholder: {
    width: 150,
    height: 150,
    borderWidth: 1,
    borderColor: '#dedede',
    borderRadius: 5,
    backgroundColor: '#efefef',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: '#dedede',
    cursor: 'pointer',
    margin: '0 auto',
  },
  image: {
    minWidth: 50,
    minHeight: 50,
    display: 'inline-block',
    borderWidth: 1,
    borderColor: '#dedede',
    borderRadius: 5,
  },
}

const ImagePicker = ({
  value,
  onChange,
  style = {},
  maxWidth = 200,
  maxHeight = 150,
  deletable,
  label = null,
}) => {
  const imageRef = useRef()
  const imageUrl = value && typeof value === 'object' ? URL.createObjectURL(value) : value

  useEffect(() => {
    const handlePaste = (e) => {
      const { items } = e.clipboardData

      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') === 0) {
          const file = items[i].getAsFile()
          if (file) onChange(file)
        }
      }
    }

    window.addEventListener('paste', handlePaste)

    return () => {
      window.removeEventListener('paste', handlePaste)
    }
  }, [])

  const onFileChange = (e) => {
    const file = e.target.files[0]

    if (file) onChange(file)
  }

  const onRemove = (e) => {
    e.preventDefault()

    onChange()
  }

  return (
    <div style={{ ...styles.container, ...style }}>
      <input hidden type="file" accept="image/*" onChange={onFileChange} ref={imageRef} />

      {label && <p style={{ fontSize: 15, fontWeight: 'bold' }}>{label}</p>}

      <div
        style={{
          ...styles.placeholder,
          maxWidth,
          maxHeight,
          position: 'relative',
          backgroundColor: imageUrl ? '#fff' : '#efefef',
        }}
        onClick={() => imageRef.current.click()}
        role="presentation"
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="Payment Request"
            style={{ ...styles.image, maxWidth, maxHeight }}
          />
        ) : (
          'Upload'
        )}
      </div>
      {deletable && (
        <Button
          size="small"
          variant="contained"
          startIcon={<Delete />}
          color="error"
          sx={{ width: 150, mt: 1 }}
          onClick={onRemove}
          disabled={!imageUrl}
        >
          Remove
        </Button>
      )}
    </div>
  )
}

export default ImagePicker
