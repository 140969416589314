import {useEffect, useState} from 'react'
import me from '../../assets/person-solid.svg'

const Marker = (options) => {
  const [marker, setMarker] = useState()

  useEffect(() => {
    if (!marker) {
      // eslint-disable-next-line no-undef
      setMarker(new google.maps.Marker())
    }

    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [marker])

  useEffect(() => {
    if (marker) {
      marker.setOptions({ ...options, icon: options.currentMarker ? me : '' })
    }
  }, [marker, options])

  return null
}

export default Marker
