import { gql } from '@apollo/client'

const GET_CRON_JOBS = gql`
  {
    boorran_Configs(where: { key: { _eq: "cron_job" } }) {
      value
    }
  }
`

const UPDATE_CRON_JOBS = gql`
  mutation ($obj: boorran_Configs_set_input!) {
    update_boorran_Configs(where: { key: { _eq: "cron_job" } }, _set: $obj) {
      affected_rows
      returning {
        value
      }
    }
  }
`

const GET_TELEGRAM_GROUPS = gql`
  {
    boorran_Configs(where: { key: { _eq: "telegram_group_ids" } }) {
      value
    }
  }
`

const UPDATE_TELEGRAM_GROUPS = gql`
  mutation ($obj: boorran_Configs_set_input!) {
    update_boorran_Configs(where: { key: { _eq: "telegram_group_ids" } }, _set: $obj) {
      affected_rows
      returning {
        value
      }
    }
  }
`

const GET_TRANSLATION = gql`
  {
    boorran_Configs(where: { key: { _eq: "translation" } }) {
      value
    }
  }
`

const UPDATE_TRANSLATION = gql`
  mutation ($obj: boorran_Configs_set_input!) {
    update_boorran_Configs(where: { key: { _eq: "translation" } }, _set: $obj) {
      affected_rows
      returning {
        value
      }
    }
  }
`

export {
  GET_CRON_JOBS,
  GET_TELEGRAM_GROUPS,
  UPDATE_TELEGRAM_GROUPS,
  UPDATE_CRON_JOBS,
  GET_TRANSLATION,
  UPDATE_TRANSLATION,
}
