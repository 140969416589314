const fields = `
  id
  account_type
  account_name
  account_number
  created_at
  updated_at
  description
  metadata
  financial_type
`

const tabQuery = (tab) => (tab === 'all' ? '' : `financial_type: { _eq: "${tab}" }`)

const qAggregateChartOfAccounts = (tab) => `{
  boorran_ChartOfAccounts_aggregate(where: { ${tabQuery(tab)} }) {
    aggregate {
      count
    }
  }
}`

const qSubscribeChartOfAccounts = (tab) => `subscription {
  boorran_ChartOfAccounts(where: { ${tabQuery(
    tab
  )} }, order_by: [{ financial_type: asc }, {created_at: asc}]) {
    ${fields}
  }
}`

export { fields, qSubscribeChartOfAccounts, qAggregateChartOfAccounts }
