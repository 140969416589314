/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
import Cookie from 'js-cookie'
import { POST, GET, wsGQLSubscribe, wsGQLUnsubscribe } from 'fetchier'
import { sumBy, meanBy } from 'lodash'

import gq from '../queries'
import { CUSTOMER_TYPES } from '../reducers/customers'
import {
  qCustomerbyUUID,
  qCustomerOrdersById,
  qSubscribeCustomers,
  qAggregateCustomers,
  qSearchCustomersByName,
  qCustomersOrdersAmount,
  qAggregateCustomersOrders,
} from '../../queries/customers'
import { HASURA_WSS, NODE_URL } from '../config'

const debug = false

export const syncCustomers = () => {
  const sessionToken = Cookie.get('sessionToken')

  return GET({
    url: `${NODE_URL}/boorran/sync/customers`,
    headers: { Authorization: sessionToken },
  })
}

export const fetchCustomers =
  (opts = {}) =>
  (dispatch) => {
    let { limit = 20, offset, page = 1, filters } = opts || {}
    offset = (page && parseInt(page - 1, 10) * limit) || offset || 0

    const action = (customers) => {
      gq.gqRequest(qAggregateCustomers(filters)).then(({ boorran_Customers_aggregate }) => {
        dispatch({
          type: CUSTOMER_TYPES.CUSTOMERS_FETCHED,
          payload: {
            limit,
            offset,
            page,
            pages: parseInt(boorran_Customers_aggregate.aggregate.count / limit, 10) + 1,
            data: { customers, customerCount: boorran_Customers_aggregate },
          },
        })
      })
      // .catch(err => dispatch(error(err)));
    }

    wsGQLUnsubscribe({ url: HASURA_WSS, id: 'customers', debug })

    const subscription = {
      id: 'customers',
      query: qSubscribeCustomers(filters),
      variables: { limit, offset },
      action,
    }
    return wsGQLSubscribe({ url: HASURA_WSS, subscription, debug })
  }

export const searchCustomers = (opt) => (dispatch) => {
  gq.gqRequest(qSearchCustomersByName, { search: `%${opt.search}%` }).then(
    ({ boorran_Customers }) => {
      dispatch({
        type: CUSTOMER_TYPES.CUSTOMERS_SEARCH,
        payload: boorran_Customers,
      })
    }
  )
}

export const getCustomerByUUID = (payload) => (dispatch) => {
  gq.gqRequest(qCustomerbyUUID, { id: payload.id }).then(({ boorran_Customers }) => {
    dispatch({
      type: CUSTOMER_TYPES.CUSTOMER_FETCHED,
      payload: boorran_Customers[0],
    })
  })
}

export const createCustomer = (data) => {
  return POST({
    url: `${NODE_URL}/v2/boorran/customer`,
    body: data,
    headers: { Authorization: Cookie.get('sessionToken') },
  })
}

export const updateCustomer = (data) => {
  const sessionToken = Cookie.get('sessionToken')

  return POST({
    url: `${NODE_URL}/boorran/customer`,
    body: data,
    headers: { Authorization: sessionToken },
  })
}

export const getCustomerDetail = (id) => {
  const sessionToken = Cookie.get('sessionToken')

  return GET({
    url: `${NODE_URL}/boorran/customer?id=${id}`,
    headers: { Authorization: sessionToken },
  })
}
export const updateCustomerAddress = (data, query) => {
  const sessionToken = Cookie.get('sessionToken')
  return POST({
    url: `${NODE_URL}/boorran/customer-address${query || ''}`,
    body: data,
    headers: { Authorization: sessionToken },
  })
}

const getCustomerOrdersCount = (payload) => (dispatch) => {
  gq.gqRequest(qAggregateCustomersOrders, { id: payload.id, status: null, deliveryStatus: null })
    .then(
      ({
        boorran_Orders_aggregate: {
          aggregate: { count },
        },
      }) => {
        dispatch({
          type: CUSTOMER_TYPES.GET_CUSTOMER_ORDERS_COUNT,
          payload: { count, customerId: payload.id },
        })
      }
    )
    .catch((e) => console.log(e.message || e))
}

const getCustomerOrdersSuccessCount = (payload) => (dispatch) => {
  gq.gqRequest(qAggregateCustomersOrders, { id: payload.id, status: true, deliveryStatus: 'Done' })
    .then(
      ({
        boorran_Orders_aggregate: {
          aggregate: { count },
        },
      }) => {
        dispatch({
          type: CUSTOMER_TYPES.GET_CUSTOMER_ORDERS_SUCCESS_COUNT,
          payload: { count, customerId: payload.id },
        })
      }
    )
    .catch((e) => console.log(e.message || e))
}

const getCustomerOrdersSuccessAmount = (payload) => (dispatch) => {
  gq.gqRequest(qCustomersOrdersAmount, { id: payload.id, status: true, deliveryStatus: 'Done' })
    .then(({ boorran_Orders }) => {
      const totalAmountSuccessOrders = sumBy(boorran_Orders, 'boorranGrandTotal')
      const totalAvgPerOrder = meanBy(boorran_Orders, 'boorranGrandTotal')

      dispatch({
        type: CUSTOMER_TYPES.GET_CUSTOMER_ORDERS_SUCCESS_AMOUNT,
        payload: {
          customerId: payload.id,
          total_avg_order: totalAvgPerOrder || 0,
          total_amount: totalAmountSuccessOrders,
        },
      })
    })
    .catch((e) => console.log(e.message || e))
}

export const getCustomerInfo = (payload) => (dispatch) => {
  dispatch(getCustomerOrdersCount(payload))
  dispatch(getCustomerOrdersSuccessCount(payload))
  dispatch(getCustomerOrdersSuccessAmount(payload))
}

export const getCustomerOrders = (payload) => (dispatch) => {
  gq.gqRequest(qCustomerOrdersById, { id: payload.id })
    .then(({ boorran_Orders }) => {
      dispatch({
        type: CUSTOMER_TYPES.GET_CUSTOMER_ORDERS,
        payload: { orders: boorran_Orders },
      })
    })
    .catch((e) => console.log('get customer orders', e.message || e))
}
