const fields = `
  id
  name
  email
  phone
  role
  createdAt
  metadata
  activationStatus
`

const filterUser = (keyword) => `
  _or: [
    {name: {_ilike: "%${keyword}%"}},
    {email: {_ilike: "%${keyword}%"}},
    {phone: {_ilike: "%${keyword}%"}}
  ]
`

const qAggregateUsers = (keyword) => `
  {
    Users_aggregate(where: {${keyword ? filterUser(keyword) : ''}}) {
      aggregate {
        count
      }
    }
  }
`

const qSubscribeUsers = (keyword) => `
  query($limit: Int, $offset: Int){
      Users(where: { ${
        keyword ? filterUser(keyword) : ''
      }}, limit: $limit, offset: $offset, order_by: {name: asc}) {
      ${fields}
    }
  }
`

const qUserById = `
  query($id: uuid) {
    Users(where: { id: { _eq: $id } }) {
      ${fields}
    }
  }
`

export { qUserById, qAggregateUsers, qSubscribeUsers }
