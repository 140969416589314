const disbursementFields = `
  id
  name: vendorName
  commission
  itemCount
  vendorId
  status
  createdAt
  updatedAt
`

const commissionFields = `
  id
  price
  cost
  commission
  deliveryRevenue
  total
  collectionId
  disbursementId
  createdAt
  updatedAt
  collectionName
  orderItemId
  discount
  productTitle
  qty
`

const shopifyMetadataFields = `id value type namespace key`

const qPendingDisbursements = (condition) => `
  query disbursements {
    disbursements: boorran_CommissionDisbursements(where: { ${condition} status: { _eq: "pending" } }) {
      ${disbursementFields}
    }
  }
`

const qCommissionsByCollectionIds = `
  query getPendingDisbursements($collectionIds: [numeric!]) {
    commissions: boorran_Commissions_aggregate (where: {disbursementId: {_is_null: true}, collectionId: {_in: $collectionIds}}) {
      aggregate { sum {  itemCount: qty totalCommission: commission } }
      nodes { ${commissionFields} }
    }
  }
`

const qHistoryDisbursements = (condition) => `
  query disbursements($limit: Int, $offset: Int){
    disbursements: boorran_CommissionDisbursements(
      where: { ${condition} status: { _eq: "completed" } }
      limit: $limit
      offset: $offset
    ) {
      ${disbursementFields}
    }

    disbursementAgg: boorran_CommissionDisbursements_aggregate(where: { ${condition} status: { _eq: "completed" } }) {
      aggregate {
        count
      }
    }
  }
`

const qGetDisbursementDetail = `
  query getDisbursementDetail($id: uuid!) {
    disbursement: boorran_CommissionDisbursements_by_pk(id: $id) {
      ${disbursementFields}
      commissions: Commissions {
        ${commissionFields}
      }
    }
  }
`

const qShopifyCollections = `
  query {
    collections(first: 100) {
      edges {
        node {
          id
          productsCount
          title
          defaultCost: metafield(key: "default_cost", namespace: "custom") {
            ${shopifyMetadataFields}
          }
          defaultCommission: metafield(key: "default_commission", namespace: "custom") {
            ${shopifyMetadataFields}
          }
          costs: metafield(key: "costs", namespace: "custom") {
            ${shopifyMetadataFields}
          }
          commissions: metafield(key: "commissions", namespace: "custom") {
            ${shopifyMetadataFields}
          }
          conditions: metafield(key: "conditions", namespace: "custom") {
            ${shopifyMetadataFields}
          }
        }
      }
    }
  }
`

const qUpdateShopifyCollection = `
  mutation collectionUpdate($input: CollectionInput!) {
    collectionUpdate(input: $input) {
      collection {
        id
        title
        defaultCost: metafield(key: "default_cost", namespace: "custom") {
            ${shopifyMetadataFields}
          }
          defaultCommission: metafield(key: "default_commission", namespace: "custom") {
            ${shopifyMetadataFields}
          }
          costs: metafield(key: "costs", namespace: "custom") {
            ${shopifyMetadataFields}
          }
          commissions: metafield(key: "commissions", namespace: "custom") {
            ${shopifyMetadataFields}
          }
          conditions: metafield(key: "conditions", namespace: "custom") {
            ${shopifyMetadataFields}
          }
      }
    }
  }
`

const qSetDisbursementIdToPendingCommissions = `
  mutation updateCommissions($collectionIds: [numeric!], $disbursementId: uuid) {
    update_boorran_Commissions(
      where: { collectionId: { _in: $collectionIds } disbursementId: { _is_null: true } }
      _set: { disbursementId: $disbursementId }
    ) {
      affected_rows
    }
  }
`

export {
  qPendingDisbursements,
  qCommissionsByCollectionIds,
  qHistoryDisbursements,
  qGetDisbursementDetail,
  qShopifyCollections,
  qUpdateShopifyCollection,
  qSetDisbursementIdToPendingCommissions,
}
