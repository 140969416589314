import React, { useState } from 'react'

import { Input, Dropdown } from 'semantic-ui-react'
import { Grid, Box, Tooltip, Typography, Paper, IconButton, CardMedia } from '@mui/material'
import { AddCircleTwoTone, RemoveCircleTwoTone } from '@mui/icons-material'

import utils from '../../../utils'

const PLACEHOLDER_URL = 'https://react.semantic-ui.com/images/wireframe/image.png'

const OrderItems = ({
  data: products = [],
  onRemove,
  onIncrease,
  onDecrease,
  editDiscount,
  onDiscountChange,
  changeDiscountType,
}) => {
  const [discountType, setDiscountType] = useState('$')

  return (
    <Box>
      {products.map((item, index) => {
        const { price, quantity, discount, productTitle, title } = item
        const discountAmount = utils.orders.calculateDiscount(price, discount, discountType)
        const priceAfterDiscount = (price - discountAmount).toFixed(2)

        return (
          <Paper sx={{ p: 1, margin: 'auto', flexGrow: 1 }} variant="outlined" key={index}>
            <Grid container spacing={2}>
              <Grid item>
                <CardMedia
                  component="img"
                  sx={{ width: 60 }}
                  src={item.source || PLACEHOLDER_URL}
                  alt="Products"
                />
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography gutterBottom variant="subtitle1" component="div" color="primary">
                      {productTitle}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {title}
                    </Typography>

                    {discount ? (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mr: 1, textDecoration: 'line-through' }}
                        >
                          ${price}
                        </Typography>
                        <Typography variant="body2" color="text" sx={{ fontWeight: 500 }}>
                          ${priceAfterDiscount}
                        </Typography>
                      </div>
                    ) : (
                      <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                        ${price}
                      </Typography>
                    )}

                    {editDiscount ? (
                      <Box sx={{ mt: 1 }}>
                        <Typography>Discount</Typography>
                        <Input
                          fluid
                          size="mini"
                          type="number"
                          placeholder="Amount"
                          labelPosition="right"
                          value={item.discount || 0}
                          onChange={(e) => {
                            if (
                              (discountType === '%' && e.target.value <= 100) ||
                              (discountType === '$' && e.target.value <= price)
                            ) {
                              onDiscountChange(item, e.target.value)
                            }
                          }}
                          label={
                            <Dropdown
                              value={discountType}
                              options={[
                                { key: '$', text: '$', value: '$' },
                                { key: '%', text: '%', value: '%' },
                              ]}
                              onChange={(e, target) => {
                                onDiscountChange(item, 0, 'remove-all')
                                setDiscountType(target.value)
                                changeDiscountType(target.value)
                              }}
                            />
                          }
                        />
                      </Box>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Tooltip title="Add">
                      <IconButton aria-label="Add" onClick={() => onIncrease(item)} size="large">
                        <AddCircleTwoTone />
                      </IconButton>
                    </Tooltip>

                    <Typography>{quantity || 0}</Typography>

                    <Tooltip title="Remove">
                      <IconButton aria-label="Remove" onClick={() => onDecrease(item)} size="large">
                        <RemoveCircleTwoTone />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="subtitle1" component="div" sx={{ fontWeight: 500 }}>
                    ${(priceAfterDiscount * quantity).toFixed(2)}
                  </Typography>
                  <Tooltip title="Delete">
                    <Typography
                      aria-label="delete"
                      onClick={() => onRemove(item)}
                      color="primary"
                      sx={{ cursor: 'pointer', fontWeight: 500 }}
                      variant="body2"
                    >
                      REMOVE
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )
      })}
    </Box>
  )
}

export default OrderItems
