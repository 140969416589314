import { gql } from '@apollo/client'

export const CUSTOMERS_COUNT = gql`
  query {
    boorran_Customers_aggregate {
      aggregate {
        count
      }
    }
  }
`
export const ADMIN_COUNT = gql`
  query ($where: boorran_Logs_bool_exp!) {
    boorran_Logs_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const ORDRES_COUNT = gql`
  query {
    boorran_Orders_aggregate {
      aggregate {
        count
        sum {
          boorranDeliveryPrice
          boorranGrandTotal
          deliveryOrderId
          deliveryPrice
          discount
          grandTotal
          itemsPrice
          subTotal
          taxPrice
        }
      }
    }
  }
`

// export const ORDRES_COUNT = gql`
//   query ($where: boorran_Orders_bool_exp!) {
//     boorran_Orders_aggregate(where: $where) {
//       aggregate {
//         count
//         sum {
//           boorranDeliveryPrice
//           boorranGrandTotal
//           deliveryOrderId
//           deliveryPrice
//           discount
//           grandTotal
//           itemsPrice
//           subTotal
//           taxPrice
//         }
//       }
//     }
//   }
// `

export const PRODUCTS_COUNT = gql`
  query {
    boorran_Products_aggregate {
      aggregate {
        count
      }
    }
  }
`

export const QUERY_FILTERS = gql`
  query filters($filters: String) {
    boorran_Orders(
      limit: 10
      order_by: { createdAt: desc }
      where: {
        _or: [
          { shopifyOrderNumber: { _ilike: $filters } }
          {
            customer: {
              _or: [{ firstName: { _ilike: $filters } }, { phone: { _ilike: $filters } }]
            }
          }
        ]
      }
    ) {
      customer {
        firstName
        lastName
        phone
      }
      createdAt
      shopifyOrderNumber
      id
      deliveryStatus
      status
      packStatus
      orderAddress
    }
  }
`
