import {useEffect, useState} from 'react'
import utils from '../../utils/constants'

const Circle = (options) => {
  const [circle, setCircle] = useState()

  useEffect(() => {
    if (!circle) {
      // eslint-disable-next-line no-undef
      setCircle(new google.maps.Circle())
    }

    return () => {
      if (circle) {
        circle.setMap(null)
      }
    }
  }, [circle])

  useEffect(() => {
    if (circle) {
      const defaultOptions = {
        radius: options?.radius ?? utils.defaultCircleRadius,
        fillColor: '#00aee8',
        fillOpacity: 0.3,
        strokeColor: "#FFF",
        strokeWeight: 0
      }
      circle.setOptions({ ...defaultOptions, center: options.position, map: options.map })
    }
  }, [circle, options])

  return null
}

export default Circle
