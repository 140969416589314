/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import { Header } from 'semantic-ui-react'
import InfoIcon from '@mui/icons-material/Info'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from '@mui/material'
import { Check, Close } from '@mui/icons-material'

const Popup = (props) => {
  const {
    open = true,
    header = '',
    children,
    maxWidth,
    actions = [],
    contentHeader,
    center = false,
    hasInvoice = false,
    closeIcon = false,
    scroll,
  } = props
  const [isInvoice, setIsInvoice] = useState(false)

  const [invoiceBtn, setInvoiceBtn] = useState('Invoice')
  const handleViewInvoice = () => {
    setIsInvoice(!isInvoice)
    setInvoiceBtn(isInvoice ? 'Invoice' : 'Back')
  }
  return (
    <Dialog
      sx={{
        backdropFilter: 'blur(9px)',
        bgcolor: 'rgba(0,0,30,0.1)',
        color: 'text.primary',
      }}
      scroll={scroll}
      open={open}
      maxWidth={maxWidth}
      // closeIcon={!!closeIcon}
      onClose={() => closeIcon && closeIcon.onClose()}
    >
      <DialogTitle sx={{ px: 3, py: 1 }}>
        <Box sx={{ textAlign: 'left', py: 1, minWidth: 250 }}>
          <Typography variant="body">{contentHeader}</Typography>

          {(typeof header === 'function' && header()) || header}
          {hasInvoice && (
            <Button onClick={handleViewInvoice}>
              {invoiceBtn}
              <InfoIcon />
            </Button>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText align="left">
          <Typography variant="body">{children}</Typography>
        </DialogContentText>
      </DialogContent>
      {!!actions.length && (
        <DialogActions
          style={{
            textAlign: center && 'center',
            paddingRight: 24,
            paddingBottom: 16,
            paddingTop: 16,
          }}
        >
          {actions.map(
            ({ disabled, loading = false, icon, color, content, action = console.log }) => (
              <Button
                sx={{ width: 120 }}
                color={color}
                variant="outlined"
                key={content}
                loading={loading}
                startIcon={icon}
                // labelPosition={labelPosition}
                // Received
                onClick={action}
                disabled={disabled}
              >
                {content}
              </Button>
            )
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}
export default Popup

export const Info = ({
  onClose = console.log,
  description = 'This is an info message.',
  iconHeader = <InfoIcon />,
  headerContent = 'Information',
  closeIcon = false,
}) => (
  <Popup
    // closeIcon={!!closeIcon}
    onClose={() => closeIcon && closeIcon.onClose()}
    center
    header={() => <Header as="h3" icon={iconHeader} content={headerContent} />}
    actions={[{ icon: <Check />, content: 'Got it', action: onClose }]}
  >
    <Typography sx={{ minheight: 270 }}>{description}</Typography>
  </Popup>
)

export const Error = ({
  onClose = console.log,
  description = 'This is an error message.',
  iconHeader = <Close />,
  headerContent = 'Error',
  closeIcon = false,
}) => (
  <Popup
    // closeIcon={!!closeIcon}
    onClose={() => closeIcon && closeIcon.onClose()}
    center
    size="mini"
    header={() => <Header as="h3" icon={iconHeader} content={headerContent} />}
    actions={[{ icon: <Check />, content: 'Got it', action: onClose }]}
  >
    <Typography sx={{ minheight: 270 }}>{description}</Typography>
  </Popup>
)
