import React from 'react'
import {
  Receipt,
  Payment,
  Refresh,
  CropFree,
  PieChart,
  Bookmarks,
  Discount,
  PeopleAlt,
  PersonAdd,
  Assessment,
  TwoWheeler,
  AddToPhotos,
  CalendarToday,
  AccountBalance,
  PrecisionManufacturing,
  Notifications,
} from '@mui/icons-material'

export default {
  admin: [
    {
      icon: <Assessment />,
      label: 'Dashboard',
      link: '/dashboard',
      selected: 'dashboard',
    },
    { icon: <Receipt />, label: 'Orders', link: '/orders', selected: 'orders' },
    { icon: <Bookmarks />, label: 'Draft Orders', link: '/draft-orders', selected: 'draft-orders' },
    // { icon: <Receipt />, label: 'Orders1', link: '/orders1', selected: 'orders1' },
    // {
    //   icon: <LocalShipping />,
    //   label: 'Shipping',
    //   link: '/shipping',
    //   selected: 'shipping',
    // },
    {
      icon: <AccountBalance />,
      label: 'Disbursement',
      link: '/disbursement',
      selected: '/disbursement',
    },
    {
      icon: <AccountBalance />,
      label: 'Commission Disbursements',
      link: '/commission-disbursements',
      selected: 'commission-disbursements',
    },
    {
      icon: <AddToPhotos />,
      label: 'Products',
      link: '/products',
      selected: 'products',
    },

    {
      icon: <AddToPhotos />,
      label: 'StockB',
      link: '/stockb',
      selected: 'stockb',
    },
    { icon: <Discount />, label: 'Discounts', link: '/discounts', selected: 'discounts' },
    {
      icon: <PeopleAlt />,
      label: 'Customers',
      link: '/customers',
      selected: 'customers',
    },
    // {
    //   icon: <MapOutlined />,
    //   label: 'Map',
    //   link: '/map',
    //   selected: 'map',
    // },
    {
      icon: <Assessment />,
      label: 'Transactions',
      link: '/transactions',
      selected: 'transactions',
    },
    {
      icon: <AccountBalance />,
      label: 'Chart Of Accounts',
      link: '/chart-of-accounts',
      selected: 'chart-of-accounts',
    },
    {
      icon: <PieChart />,
      label: 'Reports',
      link: '/reports',
      selected: 'reports',
    },
  ],
  userAccess: [
    {
      icon: <Assessment />,
      label: 'Dashboard',
      link: '/dashboard',
      selected: 'dashboard',
    },
    { icon: <Receipt />, label: 'Orders', link: '/orders', selected: 'orders' },
    { icon: <Discount />, label: 'Discounts', link: '/discounts', selected: 'discounts' },

    {
      icon: <AddToPhotos />,
      label: 'Products',
      link: '/products',
      selected: 'products',
    },
    {
      icon: <AccountBalance />,
      label: 'Disbursement',
      link: '/disbursement',
      selected: '/disbursement',
    },
    {
      icon: <PeopleAlt />,
      label: 'Customers',
      link: '/customers',
      selected: 'customers',
    },

    {
      icon: <CropFree />,
      label: 'QrReader',
      link: '/scanner',
      selected: 'scanner',
    },
    {
      icon: <AccountBalance />,
      label: 'Commission Disbursements',
      link: '/commission-disbursements',
      selected: 'commission-disbursements',
    },
  ],
  userFinance: [
    {
      icon: <Assessment />,
      label: 'Dashboard',
      link: '/dashboard',
      selected: 'dashboard',
    },
    { icon: <Receipt />, label: 'Orders', link: '/orders', selected: 'orders' },
    { icon: <Discount />, label: 'Discounts', link: '/discounts', selected: 'discounts' },
    {
      icon: <AddToPhotos />,
      label: 'Products',
      link: '/products',
      selected: 'products',
    },
    {
      icon: <AddToPhotos />,
      label: 'CCG Products',
      link: '/ccgproducts',
      selected: 'ccgproducts',
    },
    {
      icon: <AccountBalance />,
      label: 'Disbursement',
      link: '/disbursement',
      selected: '/disbursement',
    },
    {
      icon: <PieChart />,
      label: 'Reports',
      link: '/reports',
      selected: 'reports',
    },

    {
      icon: <CropFree />,
      label: 'QrReader',
      link: '/scanner',
      selected: 'scanner',
    },
    {
      icon: <AccountBalance />,
      label: 'Commission Disbursements',
      link: '/commission-disbursements',
      selected: 'commission-disbursements',
    },
  ],
  manager: [
    { icon: <Assessment />, label: 'Dashboard', link: '/dashboard', selected: 'dashboard' },
    { icon: <Receipt />, label: 'Orders', link: '/orders', selected: 'orders' },
    { icon: <Bookmarks />, label: 'Draft Orders', link: '/draft-orders', selected: 'draft-orders' },
    { icon: <Discount />, label: 'Discounts', link: '/discounts', selected: 'discounts' },
    { icon: <AddToPhotos />, label: 'Products', link: '/products', selected: 'products' },
    {
      icon: <AccountBalance />,
      label: 'Disbursement',
      link: '/disbursement',
      selected: '/disbursement',
    },
    { icon: <CropFree />, label: 'QrReader', link: '/scanner', selected: 'scanner' },
    {
      icon: <AccountBalance />,
      label: 'Commission Disbursements',
      link: '/commission-disbursements',
      selected: 'commission-disbursements',
    },
    {
      icon: <AddToPhotos />,
      label: 'CCG Products',
      link: '/ccgproducts',
      selected: 'ccgproducts',
    },
  ],
  settings: [
    {
      icon: <PersonAdd />,
      label: 'Users',
      link: '/settings/users',
      selected: 'users',
    },
    {
      icon: <Notifications />,
      label: 'Notifications',
      link: '/settings/notifications',
      selected: '/settings/notifications',
    },
    {
      icon: <PrecisionManufacturing />,
      label: 'Automation',
      link: '/settings/automation',
      selected: 'settings/automation',
    },
    {
      icon: <CalendarToday />,
      label: 'Attendance',
      link: '/settings/attendance',
      selected: 'settings/attendance',
    },
    {
      icon: <Payment />,
      label: 'Payments',
      link: '/settings/payment',
      selected: 'settings/payment',
    },
    {
      icon: <Refresh />,
      label: 'Syncing',
      link: '/settings/syncing',
      selected: 'syncing',
    },
    {
      icon: <TwoWheeler />,
      label: 'Delivery',
      link: '/settings/delivery',
      selected: 'settings/delivery',
    },
  ],
  vendor: [
    {
      icon: <PieChart />,
      label: 'Reports',
      link: '/reports',
      selected: 'reports',
    },
  ],
  sales: [
    {
      icon: <AddToPhotos />,
      label: 'CCG Products',
      link: '/ccgproducts',
      selected: 'ccgproducts',
    },
  ],
}
