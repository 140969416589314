/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  Box,
  List,
  Button,
  Divider,
  ListItem,
  CardMedia,
  TextField,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemSecondaryAction,
} from '@mui/material'
import { Check, AddCircle, Close } from '@mui/icons-material'
import { gql, useQuery } from '@apollo/client'
import { Popup } from '../..'

import utils from '../../../utils'

const imageFields = 'source shopifyImageId'
const QUERY_PRODUCTS = gql`
  query ($title: String, $limit: Int, $offset: Int) {
    boorran_Products(
      where:{
        _or:[
          {title: {_ilike: $title}}
          {ProductVariants: {title: {_ilike: $title}}}
        ]
      },
      limit: $limit,
      offset: $offset
    )
    {
      itemId
      title
      ProductImages{
        ${imageFields}
      }
      ProductVariants{
        id
        itemId
        title
        price
        imageId
        quantity
      }
    }
  }
`

const ModalProduct = ({ open, onCancel, onConfirm, query, selectedList }) => {
  const [offset, setOffset] = useState(0)
  const [search, setSearch] = useState(query)

  const [products, setProducts] = useState([])
  const [selected, setSelected] = useState(selectedList || [])

  const { data: listProducts, fetchMore } = useQuery(QUERY_PRODUCTS, {
    variables: { title: `%${search}%`, limit: 4, offset },
  })

  useEffect(() => {
    if (listProducts) {
      const filteredProducts =
        search.length > 0
          ? listProducts.boorran_Products.filter((product) =>
              product.title.toLowerCase().includes(search.toLowerCase())
            )
          : listProducts.boorran_Products

      const PRODUCTS = filteredProducts.map((product) => ({
        itemId: product.itemId,
        title: product.title,
        ProductVariants: utils.format.customSortProduct(product.ProductVariants).map((variant) => ({
          source: product.ProductImages.find(
            (item) => item.shopifyImageId === variant.imageId && item
          )?.source,
          ...variant,
        })),
      }))

      setProducts(PRODUCTS)
    }
  }, [listProducts])

  const handleSearchProduct = (event) => {
    if (event.key === 'Enter') {
      if (event.target.value.length > 0) {
        fetchMore({ variables: { title: `%${event.target.value}%`, limit: 4, offset: 0 } })
        setSearch(event.target.value)
      } else {
        const newOffset = offset + 4
        fetchMore({ variables: { limit: 4, offset: newOffset } })
        setSearch('')
      }
    }
  }

  const handleConfirm = () => {
    onConfirm(selected)
    onCancel()
  }

  const showMoreProducts = async () => {
    const newOffset = offset + 4

    fetchMore({ variables: { limit: 4, offset: newOffset } })
    setOffset(newOffset)
  }

  return (
    <Popup
      visible={open}
      closeIcon={{ onClose: onCancel }}
      actions={[
        { icon: <Close />, color: 'error', content: 'Cancel', action: () => onCancel() },
        {
          icon: <Check />,
          color: 'primary',
          content: 'Confirm',
          action: () => handleConfirm(),
        },
      ]}
    >
      <Typography variant="h6" fontWeight="bold">
        {`Select Products ${selected.length ? `(${selected.length} selected )` : ''}`}
      </Typography>
      <TextField
        fullWidth
        size="small"
        margin="normal"
        variant="outlined"
        defaultValue={search}
        placeholder="Search Product"
        onKeyUp={handleSearchProduct}
      />
      <ListProducts
        offset={offset}
        data={products}
        selected={selected}
        setSelected={setSelected}
        showMoreProducts={showMoreProducts}
        productsLength={listProducts?.boorran_Products_aggregate?.aggregate?.count || 0}
      />
    </Popup>
  )
}

export default ModalProduct

const ListProducts = ({
  setSelected,
  selected = [],
  productsLength,
  showMoreProducts,
  data: products = [],
}) => {
  const isExist = (productVariant) =>
    !!selected.length && selected.some((item) => item.itemId === productVariant.itemId)

  const onAdd = (productVariant, productTitle) => {
    const exist = isExist(productVariant)

    if (!exist) {
      const stock = productVariant.quantity
      setSelected((prev) => [...prev, { ...productVariant, quantity: 1, productTitle, stock }])
    }
  }

  return (
    <Box
      sx={{
        p: 0,
        minWidth: 400,
        maxHeight: 400,
        overflow: 'auto',
        position: 'relative',
      }}
    >
      {products.map((item) => (
        <List>
          <ListSubheader>{item.title}</ListSubheader>
          {item.ProductVariants.map((variant) => (
            <>
              <Divider component="li" />
              <ListItem key={variant.itemId}>
                <ListItemAvatar>
                  <CardMedia
                    component="img"
                    sx={{ width: 50 }}
                    title={variant.title}
                    image={
                      variant.source || 'https://react.semantic-ui.com/images/wireframe/image.png'
                    }
                    onError={(e) =>
                      (e.target.src = 'https://react.semantic-ui.com/images/wireframe/image.png')
                    }
                  />
                </ListItemAvatar>
                <ListItemText>{variant.title}</ListItemText>
                <div>
                  <ListItemSecondaryAction
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <ListItemText sx={{ mr: 4 }}>{variant.quantity}</ListItemText>
                    <IconButton
                      size="large"
                      color={!isExist(variant) ? 'primary' : 'default'}
                      aria-label={!isExist(variant) ? 'add-circle' : 'check-circle'}
                      disabled={variant.quantity < 1}
                      onClick={() => onAdd(variant, item.title)}
                    >
                      {!isExist(variant) ? (
                        <AddCircle fontSize="large" />
                      ) : (
                        <Check fontSize="large" />
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </div>
              </ListItem>
            </>
          ))}
        </List>
      ))}

      {products.length > 0 && products.length >= productsLength && (
        <Button variant="text" color="primary" onClick={showMoreProducts}>
          Load More
        </Button>
      )}
    </Box>
  )
}
