import React from 'react'
import { Chip } from '@mui/material'
import { Autorenew, Clear, MoneyOff, TaskAlt } from '@mui/icons-material'

const Payment = ({ status, sx = {} }) => {
  const color =
    {
      cancelled: 'error',
      exchanged: 'primary',
      unpaid: 'info',
      pending: 'secondary',
      paid: 'default',
    }[status] || 'default'

  const icon =
    {
      cancelled: <Clear />,
      exchanged: <Clear />,
      unpaid: <MoneyOff />,
      pending: <Autorenew />,
      paid: <TaskAlt />,
    }[status] || ''

  return (
    <Chip
      sx={{ textTransform: 'uppercase', ...sx }}
      size="small"
      icon={icon}
      color={color}
      label={status}
    />
  )
}

export default Payment
