import { COMMISSION_DISBURSEMENT_TYPES as TYPES } from '../reducers/commissionDisbursements'
import gq from '../queries'
import {
  qShopifyCollections,
  qUpdateShopifyCollection,
  qPendingDisbursements,
  qHistoryDisbursements,
  qGetDisbursementDetail,
  qCommissionsByCollectionIds,
  qSetDisbursementIdToPendingCommissions,
} from '../../queries/commissionDisbursements'

export const fetchPendingCommissionDisbursements = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TYPES.SET_LOADING, payload: true })

    const { id: userId, role } = getState().auth.data

    const condition = role === 'admin' ? '' : `vendorId: { _eq: "${userId}" }`
    const { disbursements } = await gq.gqRequest(qPendingDisbursements(condition))

    dispatch({
      type: TYPES.PENDING_COMMISSION_DISBURSEMENTS_FETCHED,
      payload: disbursements,
    })
  } catch (error) {
    console.log(`Error fetch pending commission disbursement: ${error.message || error}`)
  }
}

const getVendorCollectionIds = (users, userId) => {
  const user = users.find(({ key }) => key === userId)
  const metadata = user?.metadata?.vendor || []

  return metadata.map((vendor) => Number(vendor.replace('gid://shopify/Collection/', '')))
}

// Only vendor can view and request their undisbursed commissions
export const fetchVendorUndisbursedCommissions = () => async (dispatch, getState) => {
  const { id: userId } = getState().auth.data
  const { users } = getState().orders.allEnums
  const collectionIds = getVendorCollectionIds(users, 'b1ac049a-7ee2-4aa5-83ee-fa681ced34e1')
  // const collectionIds = getVendorCollectionIds(users, userId)

  const { commissions } = await gq.gqRequest(qCommissionsByCollectionIds, { collectionIds })

  dispatch({
    type: TYPES.UNDISBURSED_COMMISSIONS_FETCHED,

    collectionIds,
    agg: commissions.aggregate.sum,
    commissions: commissions.nodes,
  })
}

export const fetchHistoryCommissionDisbursements = (params) => async (dispatch, getState) => {
  try {
    const { id: userId, role } = getState().auth.data
    const { page = 1, perPage } = params || {}

    dispatch({
      type: TYPES.FETCH_HISTORY_COMMISSION_DISBURSEMENTS,
      payload: { pagination: { page } },
    })

    const condition = role === 'admin' ? '' : `vendorId: { _eq: "${userId}" }`

    const { disbursements, disbursementAgg } = await gq.gqRequest(
      qHistoryDisbursements(condition),
      { limit: perPage, offset: (page - 1) * perPage }
    )

    const totalPage = Math.ceil(disbursementAgg.aggregate.count / perPage)

    dispatch({
      type: TYPES.HISTORY_COMMISSION_DISBURSEMENTS_FETCHED,
      payload: {
        data: disbursements,
        pagination: { page, totalPage },
      },
    })
  } catch (error) {
    console.log(`Error fetch history commission disbursement: ${error.message || error}`)
  }
}

export const fetchCommissionDisbursementDetail = (id) => async (dispatch) => {
  try {
    const { disbursement } = await gq.gqRequest(qGetDisbursementDetail, { id })

    dispatch({
      type: TYPES.COMMISSION_DISBURSEMENT_DETAIL_FETCHED,
      payload: disbursement,
    })
  } catch (error) {
    console.log(`Error fetch commission disbursement detail: ${error.message || error}`)
  }
}

export const fetchCollections = () => async (dispatch) => {
  try {
    const { collections } = await gq.gqRequest(qShopifyCollections)

    dispatch({
      type: TYPES.COMMISSION_DISBURSEMENT_COLLECTIONS_FETCHED,
      payload: collections.edges.map((edge) => ({
        ...edge.node,
        id: edge.node.id.replace('gid://shopify/Collection/', ''),
        shopifyId: edge.node.id,
      })),
    })
  } catch (error) {
    console.log(`Error fetch collection: ${error.message || error}`)
  }
}

export const updateCollection = (params) => async () => {
  try {
    const { collection, commissions, defaultCommission, conditions, costs, defaultCost, callback } =
      params

    await gq.gqRequest(qUpdateShopifyCollection, {
      input: {
        id: collection.shopifyId,
        metafields: [
          {
            id: collection.commissions ? collection.commissions.id : undefined,
            value: commissions,
            namespace: 'custom',
            key: 'commissions',
            type: 'json',
          },
          {
            id: collection.defaultCommission ? collection.defaultCommission.id : undefined,
            value: String(defaultCommission),
            namespace: 'custom',
            key: 'default_commission',
            type: 'number_decimal',
          },
          {
            id: collection.defaultCost ? collection.defaultCost.id : undefined,
            value: String(defaultCost),
            namespace: 'custom',
            key: 'default_cost',
            type: 'number_decimal',
          },
          {
            id: collection.costs ? collection.costs.id : undefined,
            value: costs,
            namespace: 'custom',
            key: 'costs',
            type: 'json',
          },
          {
            id: collection.conditions ? collection.conditions.id : undefined,
            value: conditions,
            namespace: 'custom',
            key: 'conditions',
            type: 'json',
          },
        ],
      },
    })

    callback()
  } catch (error) {
    console.log(`Error fetch collection: ${error.message || error}`)
  }
}

export const requestCommissionDisbursement = (callback) => async (dispatch, getState) => {
  try {
    const { id: userId, name } = getState().auth.data
    const { agg, collectionIds } = getState().commissionDisbursements.undisbursedCommissions

    const {
      insert_boorran_CommissionDisbursements: { returning },
    } = await gq.gqRequest(
      gq.qUpsert('boorran_CommissionDisbursements', 'id', 'CommissionDisbursements_pkey'),
      {
        obj: {
          vendorId: userId,
          vendorName: name,
          commission: agg.totalCommission,
          itemCount: agg.itemCount,
        },
      }
    )

    await gq.gqRequest(qSetDisbursementIdToPendingCommissions, {
      collectionIds,
      disbursementId: returning[0].id,
    })

    dispatch(fetchPendingCommissionDisbursements())
    callback?.()
  } catch (error) {
    console.log(`Error pay commission disbursement: ${error.message || error}`)
  }
}
