import React, { lazy } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'
import ReactGA from 'react-ga'
import Loadable from '../components/Loadable'
import Layout from '../layout'
import { GA_TRACKING_CODE } from '../redux/config'

ReactGA.initialize(GA_TRACKING_CODE)
const Login = Loadable(lazy(() => import('../containers/login')))
const Users = Loadable(lazy(() => import('../containers/users')))
const User = Loadable(lazy(() => import('../containers/users/User')))
const Search = Loadable(lazy(() => import('../containers/users/Search')))

const Orders = Loadable(lazy(() => import('../containers/orders')))
const DraftOrders = Loadable(lazy(() => import('../containers/draftOrders')))
const CreateDraftOrders = Loadable(lazy(() => import('../containers/draftOrders/create')))
// const Orders1 = Loadable(lazy(() => import('../containers/orders/Orders')))

const Products = Loadable(lazy(() => import('../containers/products')))
const ProductDetails = Loadable(lazy(() => import('../containers/products/details')))

const StockB = Loadable(lazy(() => import('../containers/stockb')))
const Reports = Loadable(lazy(() => import('../containers/reports')))
const Payment = Loadable(lazy(() => import('../containers/settings')))
const Shipping = Loadable(lazy(() => import('../containers/shipping')))
const Dashboard = Loadable(lazy(() => import('../containers/dashboard')))
const Customers = Loadable(lazy(() => import('../containers/customers')))
const Discounts = Loadable(lazy(() => import('../containers/discounts')))
const QrScanner = Loadable(lazy(() => import('../containers/qrScanner')))
const Attendance = Loadable(lazy(() => import('../containers/attendance')))
const Disbursement = Loadable(lazy(() => import('../containers/disbursement')))
const Transactions = Loadable(lazy(() => import('../containers/transactions')))
const ChartOfAccounts = Loadable(lazy(() => import('../containers/chartOfAccounts')))
const StockBDashboard = Loadable(lazy(() => import('../containers/stockb/dashboard')))
const StockbProductDetail = Loadable(lazy(() => import('../containers/stockb/inventory/show')))
const Syncing = Loadable(lazy(() => import('../containers/settings/syncData')))
const Delivery = Loadable(lazy(() => import('../containers/settings/delivery')))
const AttendanceSetting = Loadable(lazy(() => import('../containers/settings/attendance')))
const AutomationSetting = Loadable(lazy(() => import('../containers/settings/automation')))
const CommissionDisbursements = Loadable(
  lazy(() => import('../containers/commissionDisbursements'))
)
const CustomCommission = Loadable(
  lazy(() => import('../containers/commissionDisbursements/customCommission'))
)
const CommissionDisbursementDetail = Loadable(
  lazy(() => import('../containers/commissionDisbursements/disbursementDetail'))
)

const ProductDetail = Loadable(lazy(() => import('../containers/products/product')))

const CustomerDetail = Loadable(lazy(() => import('../containers/customers/detail')))
const OrderDetail = Loadable(lazy(() => import('../containers/orders/show')))
const OrderCreate = Loadable(lazy(() => import('../containers/orders/create')))
const DisbursementRequest = Loadable(lazy(() => import('../containers/disbursement/Form')))
const DisbursementCompleted = Loadable(lazy(() => import('../containers/disbursement/Completed')))
const Notifications = Loadable(lazy(() => import('../containers/settings/notifications')))

const dataPrivateRoute = [
  {
    path: 'dashboard',
    link: <Dashboard />,
    roles: ['finance', 'manager', 'admin', 'vendor'],
  },
  {
    path: '/attendance',
    link: <Attendance />,
    roles: ['finance', 'manager', 'admin', 'driver'],
  },
  {
    path: 'orders',
    link: <Orders />,
    roles: ['finance', 'manager', 'admin', 'driver'],
  },
  {
    path: 'draft-orders',
    link: <DraftOrders />,
    roles: ['admin', 'manager'],
  },
  {
    path: 'draft-orders/create',
    link: <CreateDraftOrders />,
    roles: ['admin', 'manager'],
  },
  {
    path: 'draft-orders/:id',
    link: <CreateDraftOrders />,
    roles: ['admin', 'manager'],
  },
  // {
  //   path: 'orders1',
  //   link: <Orders1 />,
  //   roles: ['admin'],
  // },
  {
    path: 'disbursement/form',
    link: <DisbursementRequest />,
    roles: ['finance', 'manager', 'admin', 'driver'],
  },
  {
    path: 'disbursement',
    link: <Disbursement />,
    roles: ['finance', 'manager', 'admin', 'driver'],
  },
  {
    path: 'disbursement/completed',
    link: <DisbursementCompleted />,
    roles: ['finance', 'manager', 'admin', 'driver'],
  },
  {
    path: 'products',
    link: <Products />,
    roles: ['finance', 'manager', 'admin'],
  },
  {
    path: 'products/:id',
    link: <ProductDetails />,
    roles: ['finance', 'manager', 'admin'],
  },

  {
    path: 'products/:handle',
    link: <ProductDetail />,
    roles: ['finance', 'manager', 'admin'],
  },
  {
    path: 'stockb',
    link: <StockB />,
    roles: ['admin'],
  },
  {
    path: 'stockb/dashboard',
    link: <StockBDashboard />,
    roles: ['admin'],
  },
  {
    path: 'stockb/products/:id',
    link: <StockbProductDetail />,
    roles: ['admin'],
  },
  {
    path: 'discounts',
    link: <Discounts />,
    roles: ['admin', 'finance', 'vendor'],
  },
  {
    path: 'customers',
    link: <Customers />,
    roles: ['admin', 'manager'],
  },
  {
    path: 'customer/:id',
    link: <CustomerDetail />,
    roles: ['finance', 'manager', 'admin'],
  },
  {
    path: 'scanner',
    link: <QrScanner />,
    roles: ['finance', 'manager', 'admin', 'driver'],
  },
  {
    path: 'chart-of-accounts',
    link: <ChartOfAccounts />,
    roles: ['admin'],
  },
  {
    path: 'transactions',
    link: <Transactions />,
    roles: ['admin'],
  },
  {
    path: 'shipping',
    link: <Shipping />,
    roles: ['admin'],
  },
  {
    path: '/',
    link: <Orders />,
    roles: ['finance', 'manager', 'admin', 'driver'],
  },
  {
    path: 'orders/:id',
    link: <OrderDetail />,
    roles: ['finance', 'manager', 'admin'],
  },
  {
    path: 'order/create',
    link: <OrderCreate />,
    roles: ['finance', 'manager', 'admin'],
  },
  // {
  //   path: '*',
  //   link: <Orders />,
  //   roles: ['finance', 'manager', 'admin', 'driver'],
  // },
  {
    path: 'settings/users',
    link: <Users />,
    roles: ['admin'],
  },
  {
    path: 'settings/users/:id',
    link: <User />,
    roles: ['admin'],
  },
  {
    path: 'settings/users/search',
    link: <Search />,
    roles: ['admin'],
  },
  {
    path: 'settings/payment',
    link: <Payment />,
    roles: ['admin'],
  },
  {
    path: 'settings/notifications',
    link: <Notifications />,
    roles: ['admin'],
  },
  {
    path: 'settings',
    link: <Payment />,
    roles: ['admin'],
  },
  {
    path: 'settings/syncing',
    link: <Syncing />,
    roles: ['admin'],
  },
  {
    path: 'settings/delivery',
    link: <Delivery />,
    roles: ['admin'],
  },
  {
    path: 'settings/attendance',
    link: <AttendanceSetting />,
    roles: ['admin'],
  },
  {
    path: 'settings/automation',
    link: <AutomationSetting />,
    roles: ['admin'],
  },
  {
    path: 'reports',
    link: <Reports />,
    roles: ['admin', 'finance'],
  },
  {
    path: 'commission-disbursements',
    link: <CommissionDisbursements />,
    roles: ['admin', 'vendor'],
  },
  {
    path: 'commission-disbursements/collections/:id',
    link: <CustomCommission />,
    roles: ['admin'],
  },
  {
    path: 'commission-disbursements/:id',
    link: <CommissionDisbursementDetail />,
    roles: ['admin', 'vendor'],
  },
  {
    path: '*',
    link: <Orders />,
    roles: ['finance', 'manager', 'admin', 'driver'],
  },
]

export default () => {
  const { data: auth } = useSelector((state) => state.auth)
  const authenticated = !isEmpty(auth)

  let DefaultRouteComponent = Orders

  if (auth && auth.role === 'vendor') {
    DefaultRouteComponent = Reports
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          to="/login"
          element={authenticated ? <Navigate to="/orders" replace /> : <Login />}
        />
        {dataPrivateRoute.map((item) => (
          <Route
            key={item.path}
            path={item.path}
            element={
              !authenticated ? (
                <Login to="/login" />
              ) : (
                <Layout auth={auth} authenticated={authenticated}>
                  {!item.roles.includes(auth.role) ? <DefaultRouteComponent /> : item.link}
                </Layout>
              )
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  )
}
