import React, { useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { CssBaseline, StyledEngineProvider } from '@mui/material'

import Routes from './routes'
import client from './config/apollo'

import { Error, Loading } from './components'
import { ColorContextProvider } from './theme'

import AuthActions from './redux/actions/auth'

const mapState = ({ auth }) => ({
  error: auth.error && Object.keys(auth.error).length && auth.error,
  authenticated: auth.data && Object.values(auth.data).length > 0,
  authenticating: auth.data === null,
  loading: auth.loading,
})

export default () => {
  const dispatch = useDispatch()
  const { authenticating, error, loading } = useSelector(mapState)

  useEffect(() => {
    dispatch(AuthActions.authenticate())
  }, [])

  if (loading) return <Loading text="Signing you in..." />

  if (error) {
    return (
      <Error
        headerContent="Error logging in"
        description={error?.message || error}
        onClose={() => dispatch(AuthActions.error(null))}
      />
    )
  }

  return (
    <ApolloProvider client={client}>
      <StyledEngineProvider injectFirst>
        <ColorContextProvider>
          <CssBaseline />
          <Routes />
        </ColorContextProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  )
}
