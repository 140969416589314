import React from 'react'
import { Button } from '@mui/material'

const ElemDelivery = ({
  deliveryStatus,
  status,
  action,
  isCollectedByAdmin,
  refundDeliveryToStaff,
  paymentMethodRequests,
}) => {
  const waiting = (
    <Button variant="contained" disableElevation color="primary" onClick={() => action('onAccept')}>
      Accept
    </Button>
  )

  const deliveringUnpaid = [
    <Button
      key="btn_payment_request"
      variant="contained"
      disableElevation
      color="primary"
      onClick={() => action('paymentRequest')}
      sx={{ mr: 1 }}
      disabled={paymentMethodRequests.length > 0}
    >
      Payment
    </Button>,
    <Button
      key="btn_collect"
      variant="contained"
      disableElevation
      color="primary"
      onClick={() => action('onCollect')}
    >
      Collect
    </Button>,
  ]

  const deliveringPaid = (
    <Button
      variant="contained"
      disableElevation
      color="primary"
      onClick={() => action('onChargeDeliver')}
    >
      Delivery Charge
    </Button>
  )

  const success = (
    <Button variant="contained" disableElevation color="primary">
      Successful
    </Button>
  )

  const completed = (
    <Button variant="contained" disableElevation color="primary">
      Completed
    </Button>
  )

  if (deliveryStatus === 'Waiting') return waiting
  if (deliveryStatus === 'Delivering' && (status === 'unpaid' || status === 'exchanged'))
    return deliveringUnpaid
  if (deliveryStatus === 'Delivering' && status === 'paid') return deliveringPaid
  if (deliveryStatus === 'Done' && status === 'paid' && refundDeliveryToStaff !== 'done')
    return success
  if (
    deliveryStatus === 'Done' &&
    status === 'paid' &&
    refundDeliveryToStaff === 'done' &&
    isCollectedByAdmin
  )
    return completed
  return null
}

export default ElemDelivery
