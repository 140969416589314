import React from 'react'
import { Form, Label } from 'semantic-ui-react'
import DateInput from './DateInput'

const renderProps = (props) => {
  const {
    error,
    helperText,
    type,
    label,
    value,
    noLabel,
    name = '',
    rows = 3,
    style,
    className,
    placeholder,
    options = [],
    disabled = false,
    required = false,
    multiple = false,
    clearable = false,
    selection = true,
    readOnly = false,
    allowAdditions = false,
    onAddItem = () => {},
  } = props
  const capitalizeName = name.charAt(0).toUpperCase().concat(name.substring(1))

  return {
    error,
    helpertext: helperText,
    name,
    required,
    disabled,
    readOnly,
    className,
    style,
    type: type || 'text',
    // ...(type !== 'checkbox' && { fluid: type === 'dropdown' ? true : 'true' }),
    ...(type === 'textarea' && { rows }),
    ...((!noLabel && { label: label || capitalizeName }) || {}),
    placeholder:
      placeholder || (['select', 'dropdown'].includes(type) && 'Choose one') || capitalizeName,
    ...(type === 'checkbox'
      ? { checked: value || Boolean(value) || false }
      : { value: value || '' }),
    ...((['select', 'dropdown'].includes(type) && { options }) || {}),
    ...((type === 'dropdown' && {
      clearable,
      search: true,
      multiple,
      selection,
      allowAdditions,
      onAddItem,
    }) ||
      {}),
  }
}

const doneTyping = (action, ms) => (e) => {
  e.persist()

  if (e.key.length > 1 && !['Backspace'].includes(e.key)) return

  clearTimeout(timer)

  timer = setTimeout(() => {
    const { name, value, type } = e.target
    action({ name, value: type === 'number' ? parseFloat(value || 0) : value })
  }, ms)

  return
}

const getValues = (e, props, action) => {
  const { name, value, type, checked } = props

  if (type === 'checkbox') return action({ name, value: checked }, props, e)

  if (type === 'number') return action({ name, value: parseFloat(value) || 0 }, props, e)

  return action({ name, value }, props, e)
}

let timer
const Input = (props) => {
  const {
    type,
    name,
    label,
    value,
    action,
    editor,
    hidden,
    onKeyUp,
    disabled,
    style,
    className,
    live: defaultLive,
  } = props

  let Comp = null
  const live = typeof defaultLive === 'number' ? defaultLive : 1000

  switch (type) {
    case 'select':
      Comp = Form.Select
      break
    case 'dropdown':
      Comp = Form.Dropdown
      break
    case 'textarea':
      Comp = Form.TextArea
      break
    case 'checkbox':
      Comp = Form.Checkbox
      break
    case 'date':
      return (
        <div
          className={hidden ? 'hidden' : ''}
          style={{ flexDirection: 'column', flexGrow: 1, alignSelf: 'stretch' }}
        >
          <DateInput
            style={style}
            className={className}
            value={value}
            label={label}
            onChange={(date) => action({ name, value: date })}
          />
        </div>
      )
    default:
      Comp = Form.Input
  }

  return (
    <div
      className={hidden ? 'hidden' : ''}
      style={{ flexDirection: 'column', flexGrow: 1, alignSelf: 'stretch', marginTop: 10 }}
    >
      <Form>
        <Comp
          {...renderProps(props)}
          onChange={(e, inputProps) => !disabled && action && getValues(e, inputProps, action)}
          onKeyUp={(!disabled && live && onKeyUp && doneTyping(onKeyUp, live)) || null}
        />
      </Form>

      {editor && (
        <Label size="mini">
          by:
          <Label.Detail>{editor}</Label.Detail>
        </Label>
      )}
    </div>
  )
}

export default Input
