import React from 'react'
import moment from 'moment'
import { TextField } from '@mui/material'

const DateInput = ({ value, onChange, ...props }) => {
  return <TextField
    fullWidth
    type="date"
    variant="outlined"
    defaultValue={moment(value).format('YYYY-MM-DD')}
    onChange={(e) => onChange(moment(e.target.value).format('YYYY-MM-DD'))}
    { ...props }
  />
}

export default DateInput
