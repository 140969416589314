const fields = `
  id
  remark
  approved
  isReturn
  reappointDateTime
  created_at
  updated_at
`

const qShippingCancelList = () => `
subscription ($limit: Int, $offset: Int) {
      boorran_CancelRequests(
        where: {},
        limit: $limit,
        offset: $offset,
        order_by: {created_at: asc}) {
        ${fields}
        order {
          id
          shopifyOrderNumber
        }
        deliverer{
          id
          name
        }
        approver{
          id
          name
        }
      }
    }
  `

const aggregateShippingList = `{
  boorran_CancelRequests_aggregate(where: {}) {
    aggregate{
      count
    }
  }
}`

const updateCancelShipping = `
mutation CancelRequest($id: uuid, $changes: boorran_CancelRequests_set_input) {
  update_boorran_CancelRequests(
    where: {id: {_eq: $id}},
    _set: $changes)
  {
    affected_rows
  }
}
`

export { qShippingCancelList, aggregateShippingList, updateCancelShipping }
