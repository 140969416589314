const discountFields = `
  id
  type
  title
  amount
  endedAt
  createdAt
  startedAt
  productId
  collection
  variantIds
  salesChannels
  onlinePaymentType
  onlinePaymentAmount
  productName: Product {
    title
  }
`

const qDiscounts = `
  query {
    boorran_Discounts {${discountFields}}
  }
`

const insertDiscount = `
  mutation ($request: [boorran_Discounts_insert_input!]!) {
    insert_boorran_Discounts(objects: $request) {
      returning {${discountFields}}
    }
  }
`

const deleteDiscount = `
  mutation ($discountIds: [uuid!]) {
    delete_boorran_Discounts(where: { id: { _in: $discountIds } }) {
      returning {${discountFields}}
    }
  }
`

const updateDiscount = `
  mutation ($discountId: uuid, $request: boorran_Discounts_set_input) {
    update_boorran_Discounts(where: { id: { _eq: $discountId } }, _set: $request) {
      returning {${discountFields}}
    }
  }
`

const updateDiscounts = `
  mutation ($discountIds: [uuid!], $obj: boorran_Discounts_set_input) {
    update_boorran_Discounts(where: {id: {_in: $discountIds}}, _set: $obj) {
      returning {${discountFields}}
    }
  }
`

export { qDiscounts, insertDiscount, deleteDiscount, updateDiscount, updateDiscounts }
