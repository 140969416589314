import React from 'react'
import { Stack, Box } from '@mui/material'
import { TopNavigation, Footer } from '../components'

export default function MainLayout({ children }) {
  return (
    <Box bgcolor="background.default" color="text.primary">
      <TopNavigation />
      <Stack direction="column" spacing={2} justifyContent="center">
        {/* <SideMenu /> */}
        {children}
        <Footer />
      </Stack>
    </Box>
  )
}
