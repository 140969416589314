/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import Cookie from 'js-cookie'
import { GET, wsGQLSubscribe, wsGQLUnsubscribe } from 'fetchier'
import reduce from 'lodash/reduce'
import gq from '../queries'
import { PRODUCT_TYPES } from '../reducers/products'
import {
  qSingleProduct,
  qProductItemIds,
  qProductTitleByIds,
  qSubscribeProducts,
  qAggregateProducts,
  qSearchProductsByTitle,
  qProductImageByImageId,
  gqVariantsIdsByProductIds,
  qProductVariantByShopifyId,
} from '../../queries/products'
import { HASURA_WSS, NODE_URL } from '../config'

import { customSortProduct } from '../../utils/format'

import { DISCOUNT_TYPES } from '../reducers/discounts'
import { TYPES as CONFIGS_TYPES } from '../reducers/configs'
const sessionToken = Cookie.get('sessionToken')
const debug = false

export const syncProducts = () =>
  GET({
    url: `${NODE_URL}/boorran/sync/products`,
    headers: { Authorization: sessionToken },
  })

export const fetchProducts =
  (opts = {}) =>
  (dispatch) => {
    let { limit = 20, offset, page = 1 } = opts || {}
    offset = (page && parseInt(page - 1, 10) * limit) || offset || 0

    const action = (products) => {
      gq.gqRequest(qAggregateProducts()).then(({ boorran_Products_aggregate }) => {
        dispatch({
          type: PRODUCT_TYPES.PRODUCTS_FETCHED,
          payload: {
            limit,
            offset,
            page,
            pages: parseInt(boorran_Products_aggregate.aggregate.count / limit, 10) + 1,
            data: { products, productCount: boorran_Products_aggregate },
          },
        })
      })
      // .catch(err => disspatch(error(err)));
    }

    wsGQLUnsubscribe({ url: HASURA_WSS, id: 'products', debug })

    const subscription = {
      id: 'products',
      query: qSubscribeProducts(),
      variables: { limit, offset },
      action,
    }
    return wsGQLSubscribe({ url: HASURA_WSS, subscription, debug })
  }

export const searchProducts = (opt) => (dispatch, getState) => {
  const { search: stateSearch } = getState().products

  return gq
    .gqRequest(qSearchProductsByTitle, {
      title: `%${opt.query}%`,
      limit: opt.limit,
      offset: opt.offset,
    })
    .then(({ boorran_Products }) => {
      const products = boorran_Products.map((product) => ({
        itemId: product.itemId,
        title: product.title,
        ProductVariants: customSortProduct(product.ProductVariants).map((variant) => ({
          source: product.ProductImages.find(
            (item) => item.shopifyImageId === variant.imageId && item
          )?.source,
          ...variant,
        })),
      }))

      const result = opt.type === 'load-more' ? [...stateSearch, ...products] : products

      dispatch({
        type: PRODUCT_TYPES.PRODUCTS_SEARCH,
        payload: result,
      })
    })
    .catch((err) => {
      alert('Search Products', err.message)
    })
}

export const getProductDetail = (productId) => (dispatch) => {
  gq.gqRequest(qSingleProduct, { productId }).then(({ product, variants }) => {
    const productVariants = customSortProduct(variants).map((variant) => ({
      ...variant,
      image: product[0].ProductImages.find((image) => image.shopifyImageId === variant.imageId),
    }))
    dispatch({
      type: PRODUCT_TYPES.PRODUCT_FETCHED,
      payload: {
        title: product[0].title,
        variants: productVariants,
      },
    })
  })
}

export const getProducts = async () => {
  try {
    const res = await GET({
      url: `${NODE_URL}/store-front/products`,
      headers: { Authorization: Cookie.get('sessionToken') },
    })

    if (res.statusCode !== 200) return new Error('Error get products')

    const data = reduce(
      res.data,
      (result, product) => {
        result.products.push({
          key: product.id,
          value: product.id,
          text: product.title,
        })

        product.variants.forEach((val) => {
          result.variants.push({
            key: val.itemId,
            value: val.itemId,
            quantity: val.quantity,
            productId: val.productId,
            disabled: val.quantity <= 0,
            text: `${val.title} (${val.quantity}stock)`,
          })
        })

        return result
      },
      {
        variants: [],
        products: [],
      }
    )

    return data
  } catch (err) {
    throw new Error(err)
  }
}

export const fetchProductByItemIds = (itemIds) => async (dispatch) => {
  try {
    const { boorran_Products } = await gq.gqRequest(qProductItemIds, { itemIds })

    dispatch({
      type: PRODUCT_TYPES.SET_PRODUCTS,
      payload: boorran_Products,
    })
  } catch (err) {
    alert('fetchProductByItemIds', err)
  }
}

export const getProductImage = (variantId) =>
  gq
    .gqRequest(qProductVariantByShopifyId, { variantId: `${variantId}` })
    .then(async ({ variant: [{ imageId, stock }] }) => {
      if (imageId) {
        const {
          image: [{ source }],
        } = await gq.gqRequest(qProductImageByImageId, { imageId })

        return { source, stock }
      }

      return { source: null, stock }
    })

export const fetchProductsTitle = (productIds) =>
  gq.gqRequest(qProductTitleByIds, { productIds }).then((boorran_Products) => boorran_Products)

export const getProductVariants = (productIds) =>
  gq
    .gqRequest(gqVariantsIdsByProductIds, { productIds })
    .then(({ boorran_ProductVariants }) => boorran_ProductVariants)

export const setQueryCollection = (payload) => (dispatch) => {
  dispatch({
    type: PRODUCT_TYPES.SET_QUERY_COLLECTION,
    payload,
  })
}
