import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { POST } from 'fetchier'
import Cookie from 'js-cookie'
import {
  Alert,
  AlertTitle,
  AppBar as MuiAppBar,
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  styled,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import {
  Brightness4,
  Brightness7,
  Check,
  CheckCircleOutline,
  ChevronLeft,
  ChevronRight,
  Close,
  EventNote,
  Face,
  Inventory,
  MenuOutlined,
  Notifications,
  Refresh,
  Textsms,
} from '@mui/icons-material'
import moment from 'moment'
import get from 'lodash/get'
import { getAuth, signOut } from 'firebase/auth'

import SideMenu from './SideMenu'
import { Error, Form, Info, Popup } from '..'
import { ColorModeContext } from '../../theme'

import ModalFeedback from './Modal/ModalFeedback'
import ModalPurchaseOrder from './Modal/ModalPurchaseOrder'
import ModalAttendance from '../../containers/attendance/ModalAttendance'

import { NODE_URL } from '../../redux/config'
import AuthActions from '../../redux/actions/auth'

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const TopNavigation = React.memo(() => {
  const dispatch = useDispatch()
  const auth = getAuth()
  const handleLogout = (event) => {
    event.preventDefault()
    signOut(auth)
      .then(() => {
        dispatch(AuthActions.logout())
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const theme = useTheme()

  const {
    auth: { data: user },
    attendances: { data: attendance },
    settings: { attendance: attendanceConfig },
    notifications: { todayData },
  } = useSelector((store) => store)

  const [openNotification, setOpenNotification] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState({})

  const [info, setInfo] = useState(false)
  const [open, setOpen] = useState(false)
  const [successClockIO, setSuccessClockIO] = useState(false)
  const [attendanceModal, setAttendanceModal] = useState(false)

  const [visibleModal, setVisibleModal] = useState(false)
  const [modalFeedback, setModalFeedback] = useState(false)
  const [modalPurchaseOrder, setModalPurchaseOrder] = useState(false)

  const rolesApproval = get(attendanceConfig, ['rolesApproval'], [])
  const attendanceEnable = get(attendanceConfig, ['enabled'], false)
  const attendanceFeatureAvailable = attendanceEnable && rolesApproval.includes(user.role)

  const showClockInReminder = attendanceFeatureAvailable && !attendance['clock-in']
  const navigate = useNavigate()

  const FIELDS = [
    {
      fields: [
        {
          type: 'password',
          value: formState.current,
          label: 'Current Password',
          placeholder: 'Enter Your Current Password',
          name: 'current',
        },
        {
          type: 'password',
          value: formState.pin,
          label: 'New Password',
          placeholder: 'Enter Your New Password',
          name: 'pin',
        },
        {
          type: 'password',
          value: formState.confirmation,
          label: 'Confirm Password',
          placeholder: 'Enter Your Confirmation Password',
          name: 'confirmation',
        },
      ],
    },
  ]

  const handleDrawerOpen = () => setOpen(true)

  const handleDrawerClose = () => setOpen(false)

  const handleFormState = ({ name, value }) => {
    const newState = {
      ...formState,
      [name]: value ? value.trim() : null,
    }
    setFormState({ ...newState })
  }

  const handleSave = () => {
    // return resetPin()
    const sessionToken = Cookie.get('sessionToken')
    const req = {
      url: `${NODE_URL}/auth/reset-pin`,
      body: formState,
      headers: { Authorization: sessionToken },
    }
    setLoading(true)
    return POST(req)
      .then(() => {
        setInfo({ message: 'Update Successfully!' })
        setVisibleModal(false)
        setFormState({})
      })
      .catch((err) => setError({ message: err.message }))
      .finally(() => setLoading(false))
  }

  const validateField = (obj) => Object.values(obj).some((x) => x === null || x === '')

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleModalAttendanceAction = () => {
    setAnchorEl(null)
    setAttendanceModal(!attendanceModal)
    setSuccessClockIO(!successClockIO)
  }

  const renderAttendanceRemidner = () => {
    const attendanceType = !attendance['clock-in'] ? 'Clock In' : 'Clock Out'

    return (
      <Alert
        variant="filled"
        severity="warning"
        icon={<EventNote />}
        onClick={() => setAttendanceModal(!attendanceModal)}
        sx={{ position: 'fixed', right: 20, top: 75, zIndex: 2, cursor: 'pointer' }}
      >
        <AlertTitle>{`${attendanceType} Reminder`}</AlertTitle>
        {`It's time to get to work. Don't forget to ${attendanceType}`}
      </Alert>
    )
  }

  const renderClockInSuccessAlert = () => {
    const attendanceType = attendance['clock-in'] ? 'Clock In' : 'Clock Out'

    return (
      <Alert
        variant="filled"
        severity="success"
        icon={<CheckCircleOutline />}
        onClose={() => setSuccessClockIO(!successClockIO)}
        sx={{ position: 'fixed', right: 20, top: 75, zIndex: 2 }}
      >
        <AlertTitle>{attendanceType} Success</AlertTitle>
        Thanks for your {attendanceType}
      </Alert>
    )
  }
  const { toggleColorMode } = useContext(ColorModeContext)

  return (
    <Box sx={{ display: 'flex', pb: 9 }}>
      <AppBar
        position="fixed"
        color="primary"
        enableColorOnDark
        sx={{
          backdropFilter: 'blur(9px)',
          bgcolor: 'rgba(0,0,30,0.1)',
          color: 'text.primary',
        }}
        open={open}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuOutlined />
          </IconButton>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            <Button
              component={RouterLink}
              to="/"
              sx={{
                bgcolor: 'background.default',
                color: 'text.primary',
              }}
            >
              Boorran ERP
            </Button>
          </Typography>
          {['admin', 'finance'].includes(user.role) && (
            <IconButton
              edge="start"
              sx={{ mr: 2 }}
              onClick={() => setModalPurchaseOrder(!modalPurchaseOrder)}
            >
              <Inventory />
            </IconButton>
          )}
          <IconButton
            edge="start"
            sx={{ mr: 1.5 }}
            onClick={() => setModalFeedback(!modalFeedback)}
          >
            <Textsms />
          </IconButton>
          <IconButton edge="start" sx={{ mr: 1.5 }} onClick={() => navigate(0)}>
            <Refresh />
          </IconButton>
          <Chip
            color="default"
            edge="end"
            icon={<Face />}
            label={user.name}
            onClick={handleClick}
          />
          <Menu id="menu-appbar" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'background.default',
                color: 'text.primary',
                borderRadius: 1,
                m: 1,
              }}
            >
              {theme.palette.mode} mode
              <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
                {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
              </IconButton>
            </Box>
            <Divider />

            {attendanceFeatureAvailable && (
              <MenuItem onClick={() => setAttendanceModal(!attendanceModal)}>Attendance</MenuItem>
            )}
            <MenuItem onClick={() => setVisibleModal(!visibleModal)}>Change Password</MenuItem>

            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
          <Popup
            open={visibleModal}
            header="Change Password"
            actions={[
              {
                icon: <Close />,
                loading,
                content: 'Cancel',
                color: 'error',
                action: () => setVisibleModal(!visibleModal),
              },
              {
                disabled: validateField(formState),
                icon: <Check />,
                loading,
                content: 'Save',
                color: 'primary',
                action: () => handleSave(),
              },
            ]}
          >
            <Form fields={FIELDS} action={handleFormState} />
          </Popup>
          {info && <Info description={info.message || ''} onClose={() => setInfo(false)} />}
          {error && (
            <Error description={error.message || ''} onClose={() => setError(false)} />
          )}{' '}
          {attendanceModal ? (
            <ModalAttendance
              staffId={user.id}
              visible={attendanceModal}
              clockInTime={moment().format('x')}
              handleCancel={handleModalAttendanceAction}
            />
          ) : null}
        </Toolbar>
      </AppBar>

      {showClockInReminder && renderAttendanceRemidner()}
      {successClockIO && renderClockInSuccessAlert()}

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <SideMenu />
      </Drawer>

      {modalFeedback && (
        <ModalFeedback open={modalFeedback} onClose={() => setModalFeedback(!modalFeedback)} />
      )}

      {modalPurchaseOrder && (
        <ModalPurchaseOrder
          open={modalPurchaseOrder}
          onClose={() => setModalPurchaseOrder(!modalPurchaseOrder)}
        />
      )}

      <Fab
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        variant="extended"
        size="medium"
        color="primary"
        aria-label="add"
        onClick={() => setOpenNotification(!openNotification)}
      >
        <Notifications sx={{ mr: 0 }} />
        
      </Fab>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openNotification}
        onClose={() => setOpenNotification(false)}
      >
        <Alert onClose={() => setOpenNotification(false)} severity="info" sx={{ width: '100%' }}>
          {(todayData || []).map((data) => (
            <div key={data.id} style={{ marginBottom: 4 }}>
              <span style={{ whiteSpace: 'pre-line' }}>{data.message}</span>
            </div>
          ))}
        </Alert>
      </Snackbar>
    </Box>
  )
})

export default TopNavigation
