import { createClient } from 'graphql-ws'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
// import { offsetLimitPagination } from '@apollo/client/utilities'
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'

import { HASURA_URL, HASURA_KEY, HASURA_WSS } from '../redux/config'

const httpLink = createHttpLink({ uri: HASURA_URL })
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-hasura-admin-secret': HASURA_KEY,
    },
  }
})

const wsLink = new GraphQLWsLink(
  createClient({
    uri: HASURA_WSS,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          'x-hasura-admin-secret': HASURA_KEY,
        },
      },
    },
  })
)

export default new ApolloClient({
  link: authLink.concat(httpLink, wsLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          boorran_Products: {
            keyArgs: [],
            merge: (existing = [], incoming) => {
              return [...existing, ...incoming]
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})
